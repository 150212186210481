import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { PUBLIC_URL } from '../app/helper/EnvVar';
import pocketlogo from '../../src/templates/images/pocketsmall.svg';
import SVG from "react-inlinesvg";
import { getTemplateUserDataByMobileNumber } from '../app/modules/template/redux/TemplateUserDataCRUD';
import { getUserByQueryStringToken } from '../app/modules/auth/redux/AuthCRUD';
import { useDispatch } from "react-redux";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import { TemplateUserDataModel } from "../app/modules/template/models/TemplateUserDataModel";
import { SITE_TITLE } from '../app/helper/EnvVar';
import { MobileUserData } from './MobilePreview';
interface AboutPrintProps {
 token:string
}
type TokenUser = {
  data : MobileUserData
}
type UserData = {
  data: TemplateUserDataModel
}
function AboutPrint({token}:AboutPrintProps) {
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [templateColor, setTemplateColor] = useState('');
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [tokenUser, setTokenUser] = useState<TokenUser>();
  const [userData, setUserData] = useState<UserData>();
  const [error, setError] = useState<string>();
  const pageTitle = SITE_TITLE;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTemplateUserDataByMobileNumber(params.mobileNumber);
        setFirstName(response.data.data[0].firstName);
        setLastName(response.data.data[0].lastName);
        setTemplateColor(response.data.data[0].templateColor);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (params.mobileNumber) {
      fetchData();
    }
  }, [params.mobileNumber]);

  useEffect(() => {
    if (!isLoading) {
      window.print();
    }
  }, [isLoading]);

  useEffect(() =>{
    if(token){
     getUserByQueryStringToken(token).then(res => {
       setTokenUser(res.data)
       dispatch(templateUserDataRedux.actions.setUserTemplateByID(true))
     }).catch(err => {
       setError(err)
       console.error(err,'err')
     });
    } 
   },[token])
   useEffect(()=>{
    if(tokenUser){
      getTemplateUserDataByMobileNumber(tokenUser?.data?.first_name).then(res => {
        setUserData(res?.data)
        dispatch(templateUserDataRedux.actions.setUserTemplateByID(true))
      }).catch(err => {
        console.error(err)
      })
    }
  },[tokenUser])
  useEffect(() => {
    document.title = `Print | ${pageTitle} `;
  }, []);
  return (
   <>
   <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          marginTop: "20px"
        }}
      >
        <div
          style={{
            color: templateColor,
            fontSize: "24px",
            marginTop: "10px"
          }}
        >
          {firstName ? firstName : ''} {lastName ? lastName : ''}
        </div>
        <QRCodeSVG
          value={`${PUBLIC_URL}/${params?.mobileNumber}`}
          width="230"
          height="230"
          level="M"
          style={{ marginTop: "20px" }}
          imageSettings={{
            src: pocketlogo,
            width: 26,
            height: 26,
            excavate: true
          }}
        />
        <br />
        <table>
          <tr>
            <td>Powered by :</td>
            <td>
              <span className="ps-2 pe-2">
                <SVG
                  src={pocketlogo}
                  className="logosmall"
                  width={18}
                  height={18}
                />
              </span>
            </td>
            <td>PocketSite.me</td>
          </tr>
        </table>
      </div>
    </div>
    </>
  );
}

export default AboutPrint;


