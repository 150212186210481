import React, { ReactNode } from "react";
import { useEffect, useState, lazy } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as templateUserDataRedux from "../../modules/template/redux/TemplateUserDataRedux";
import { nanoid } from "nanoid";
import _toNumber from "lodash/toNumber";
import { RootState } from "../../../setup";
import { ProtectedRoute } from "../../../components/utilities/ProtectedRoute";
import { AuthDataRedux } from "../../modules/auth/redux/ReduxDispatchExport";
import { TemplateListModel } from "../../modules/template/models/TemplateListModel";
import EditTemplateNew from "../../../pages/EditTemplate";
import {
  EDIT_TEMPLATE_NEXT_CLICK,
  EDIT_TEMPLATE_PREVIOUS_CLICK,
} from "../../helper/EventsConst";
import { tracker } from "../../helper/helper";

type TemplateID = {
  id: string;
  tempKey: string;
};

const updateUrl = (url: string) => {
  window.history.pushState({}, "", url);
  window.history.replaceState({}, "", url);
};

const importView = (tempId: number) =>
  lazy(() =>
    import(`./components/Templates/Template${tempId}`).catch(
      () => import(`../NotFound/NotFoundComponent`)
    )
  );

const EditTemplate: React.FC = () => {
  // const params = useParams();
  // console.log(params,'params');
  // const tempKey = params.tempKey
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const templateUserData = useSelector(
    (state: RootState) =>
      state &&
      state.templateUserData &&
      state.templateUserData.templateUserData &&
      state.templateUserData.templateUserData!
  );

  const [templateKey, setTemplateKey] = useState<string[]>([]);
  // const templateKeyIndex = tempKey ? templateKey.indexOf(tempKey) : -1;
  // const templateIdString = templateKeyIndex.toString();
  // const [templateId, setTemplateId] = useState<number>(_toNumber(templateKey.indexOf(templateIdString)));
  const [templateId, setTemplateId] = useState<number>(
    templateUserData?.selectedTemplateID || 6
  );
  const [templateIds, setTemplateIds] = useState<number[]>([]);
  const [templateCurrentId, setTemplateCurrentId] = useState<number>(1);

  const { templateList } = useSelector((state: RootState) => state.template);
  const tempArrayIdList: number[] = [];
  const createArrayListOfID = () => {
    templateList?.forEach((element: TemplateListModel) => {
      tempArrayIdList.push(element.id);
    });
    setTemplateIds(tempArrayIdList);
  };
  const tempArrayKeysList: string[] = [];
  // const createArrayListOfKeys = () =>{
  //   templateList?.forEach((element:TemplateListModel)=>{
  //     tempArrayKeysList.push(element.templateKey)
  //     setTemplateKey(tempArrayKeysList)
  //   })
  // }

  const [, setViews] = useState<ReactNode>([]);
  const selectedCurrentId = templateUserData?.selectedTemplateID;
  useEffect(() => {
    if (!auth.isAuthorized) {
      dispatch(AuthDataRedux.actions.logout());
      navigate("/login");
    }
  }, [auth.isAuthorized]);

  useEffect(() => {
    createArrayListOfID();
    // createArrayListOfKeys()
    const idx = templateIds?.indexOf(templateId);
    if (idx === -1) {
      setTemplateCurrentId(idx + 2);
    } else {
      idx && setTemplateCurrentId(idx + 1);
    }

    const tempId = templateId;
    async function loadViews(tempId: number) {
      const componentPromises = async (tempId: number) => {
        const View = await importView(tempId);
        const naid: string = nanoid(10);
        return <View key={naid} editMode={true} />;
      };
      componentPromises(tempId).then(setViews);
    }

    const templateKeysToExclude = [
      "wave-template",
      "cross-template",
      "usercentered-template",
      "rectangleuserleft-template",
      "rectangleuserright-template",
    ];
    const templateIdsToExclude = [6, 7, 8, 9, 10];
    if (!templateIdsToExclude.some((key) => templateIds.includes(key))) {
      const selectedTemplate = templateList?.find(
        (template) => template.id === selectedCurrentId
      );
      console.log(templateList,'templateList')
      console.log(selectedCurrentId,'selectedCurrentId')
      console.log(selectedTemplate,'selectedTemplate')
      if (selectedTemplate) {
        // navigate(`/edit-template/${selectedTemplate.templateKey}`);
        navigate(`/edit-template/${selectedTemplate?.id}`);
        if (selectedCurrentId !== undefined) {
          setTemplateId(selectedCurrentId);
        }
      } else {
        //  setTemplateId(templateKey.indexOf(templateKey[0]))
        setTemplateId(templateIds[0]);
      }
    } else {
      loadViews(tempId);
      if (templateUserData) {
        dispatch(
          templateUserDataRedux.actions.setTemplateUserData({
            ...templateUserData,
            selectedTemplateID: templateId,
          })
        );
      }
    }
  }, [dispatch, templateId, templateCurrentId, templateList]);
  const handleNextClick = () => {
    if (templateIds) {
      const idx = templateIds.indexOf(templateId);
      if (idx === templateIds.length - 1) {
        setTemplateId(templateIds[0]);
        // updateUrl(templateKey[0].toString())
        updateUrl(templateIds[0].toString());
        setTemplateCurrentId(templateIds.length - idx);
        tracker({
          eventName: EDIT_TEMPLATE_NEXT_CLICK,
          dataInfo: {
            templateId: templateIds[0],
            mobileNumber: templateUserData?.mobileNumber,
            userName:
              templateUserData?.firstName + " " + templateUserData?.lastName,
          },
        });
      } else {
        setTemplateId(templateIds[idx + 1]);
        setTemplateCurrentId(idx + 1 + 1);
        // updateUrl(templateKey[idx + 1].toString())
        updateUrl(templateIds[idx + 1].toString());
        tracker({
          eventName: EDIT_TEMPLATE_NEXT_CLICK,
          dataInfo: {
            templateId: templateIds[idx + 1],
            mobileNumber: templateUserData?.mobileNumber,
            userName:
              templateUserData?.firstName + " " + templateUserData?.lastName,
          },
        });
      }
    }
  };

  const handlePreviousClick = () => {
    if (templateIds) {
      const idx = templateIds.indexOf(templateId);
      if (idx === 0) {
        templateList && setTemplateId(templateIds[templateList.length - 1]);
        templateList && setTemplateCurrentId(templateList.length - 1 + 1);
        // templateList && updateUrl(templateKey[templateList.length - 1].toString())
        templateList &&
          updateUrl(templateIds[templateList.length - 1].toString());
        tracker({
          eventName: EDIT_TEMPLATE_PREVIOUS_CLICK,
          dataInfo: {
            templateId: templateList && templateIds[templateList.length - 1],
            mobileNumber: templateUserData?.mobileNumber,
            userName:
              templateUserData?.firstName + " " + templateUserData?.lastName,
          },
        });
      } else {
        setTemplateId(templateIds[idx - 1]);
        setTemplateCurrentId(idx - 1 + 1);
        // updateUrl(templateKey[idx - 1].toString())
        updateUrl(templateIds[idx - 1].toString());
        tracker({
          eventName: EDIT_TEMPLATE_PREVIOUS_CLICK,
          dataInfo: {
            templateId: templateIds[idx - 1],
            mobileNumber: templateUserData?.mobileNumber,
            userName:
              templateUserData?.firstName + " " + templateUserData?.lastName,
          },
        });
      }
    }
  };

  return (
    <>
      <ProtectedRoute>
        <EditTemplateNew
          templateId={templateId}
          handleNextClick={handleNextClick}
          handlePreviousClick={handlePreviousClick}
          templateList={templateIds?.length && templateIds?.length}
          currentTemplateNumber={templateCurrentId}
          templateListAlldata={templateList}
        />
      </ProtectedRoute>
    </>
  );
};

export default EditTemplate;
