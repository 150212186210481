import React, { ReactNode, Ref, useState } from "react";
import { useSelector } from "react-redux";
import ReactSelect, {
  ActionMeta,
  components,
  CSSObjectWithLabel,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import { ASSEST_URL } from "../app/helper/EnvVar";
import { iDToValueResolveSelect } from "../app/helper/helper";
import { ServicesListModel } from "../app/modules/template/models/ServicesModel";
import { RootState } from "../setup";
import { KTSVG } from "../_start/helpers";
import SVG from "react-inlinesvg";
import close from "../../src/templates/images/icons/close.svg";
import { FormikErrors } from "formik/dist/types";
import { IConOption } from "../app/pages/templates/helper/ServiceOptionsModal";

type ReduceProps = {
  value: string;
  label: string;
  svg: { filename_disk: string };
  name?: string;
};

export type ReactSelectOnChange =  | ((
  newValue: SingleValue<{ options: unknown[] }> | MultiValue<{ options: unknown[] }>,
  actionMeta: ActionMeta<{ options: unknown[] }>
) => void)
| undefined;

type Props = {
  placeholder?: string | ReactNode;
  onChange?: ReactSelectOnChange;
  isTouched?: boolean;
  isMulti?: boolean;
  errorMsg?: string | string[] | FormikErrors<string> | FormikErrors<string>[];
  name?: string;
  value?: ReduceProps;
  preValue?: string;
  id?: string;
  data: ServicesListModel[];
  selectedServiceList?: string[] | ServicesListModel[];
  tabIndex?: number;
  autoFocus?: boolean;
  reference?: Ref<Select<{ options: unknown[]; }, boolean, GroupBase<Option>>> | undefined;
  handleRemove?: (e: React.MouseEvent<HTMLSelectElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
};

const { Option } = components;

const IconOption = (props: IConOption) => (
  <Option {...props}>
    <div className="row align-items-center" style={{ fontSize: "15px" }}>
      <div className="col-2">
        <KTSVG
          className="svg-icon-1 svg-icon-lg-1"
          path={`${ASSEST_URL}/${props?.data?.svg?.filename_disk}`}
          svgClassName="fillSvgColor"
          style={{
            color: "#20d489",
            fontSize: "20px",
            width: "20px",
            height: "20px",
          }}
        />
      </div>
      <div className="col-10 ps-0 service-label">{props.data.label}</div>
    </div>
  </Option>
);

const SelectDropdown: React.FC<Props> = ({
  placeholder,
  onChange,
  name,
  id,
  data,
  onBlur,
  value,
  onFocus,
  handleRemove,
  tabIndex,
  autoFocus,
  reference,
}) => {
  const [servicesModal, setServicesModal] = useState<boolean>(false);
  const userTemplateData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData?.templateUserData
    );

    const { selectedServices } = useSelector(
      (state: RootState) => state && state.templateUserData
      );

    
    const newOption = iDToValueResolveSelect(data);
    const reduceProps = ['value', 'label', "svg"];
    const result = newOption.filter((o1: {
      value: string
    }) => {
      return selectedServices && !selectedServices.some((o2: string) => {
        return o1.value == o2;  
    });
  }).map((o: any) => {
    // use reduce to make objects with only the required properties
    // and map to apply this to the filtered array as a whole
      return reduceProps.reduce((newo: any, name: any) => {       
        newo[name] = o[name];
        return newo;
      }, {});
  });

  const options = [
    {
      options: [...result],
    },
  ];

  const handleClose = () => {
    if(userTemplateData){
      if (userTemplateData.industry) {
        setServicesModal(!servicesModal);
      }
    }
  };

  const CustomNoOption = () => {
    return (
      <>
        {userTemplateData?.industry?.id ? (
          <>
            <p>
              {`Didn't find the service you are looking for?, let us know what
              services we need to add.`}
            </p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClose}
            >
              Request New Service(s)
            </button>
          </>
        ) : (
          <>
            <p>
              There is no match for service
            </p>
          </>
        )}
      </>
    );
  };

  const colorStyles:StylesConfig<Option, boolean, GroupBase<Option>> = {
    placeholder: (defaultStyles: CSSObjectWithLabel) => {
      return {
        ...defaultStyles,
        color: "#333333",
      };
    },
    control:(defaultStyles: CSSObjectWithLabel) => {
      return {
        ...defaultStyles,
        paddingLeft: '40px',
        height: '46px'
      };
    }
  };
  
  const defaultcolorStyles:StylesConfig<Option, boolean, GroupBase<Option>> = {
    placeholder: (defaultStyles: CSSObjectWithLabel) => {
      return {
        ...defaultStyles,
      };
    },
    control:(defaultStyles: CSSObjectWithLabel) => {
      return {
        ...defaultStyles,
        paddingLeft: '40px',
        height: '46px'
      };
    }
  };

  return (
    <>   
      {value ? (
        <ReactSelect
          placeholder={value?.name}
          className="mb-3"
          styles={value?.name ? colorStyles : defaultcolorStyles}
          options={options}
          onBlur={onBlur}
          onFocus={onFocus}
          components={{ Option: IconOption }}
          onChange={onChange}
          name={name}
          id={id}
          noOptionsMessage={CustomNoOption}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          ref={reference}
          tabSelectsValue={false}
          openMenuOnFocus={reference ? true : false}
        ></ReactSelect>
      ) : (
        <>
          <ReactSelect
            placeholder={placeholder}
            className="mb-3"
            styles={value?.name ? colorStyles : defaultcolorStyles}
            options={options}
            onBlur={onBlur}
            onFocus={onFocus}
            components={{ Option: IconOption }}
            onChange={onChange}
            name={name}
            id={id}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
            ref={reference}
            tabSelectsValue={false}
            openMenuOnFocus={reference ? true : false}
          ></ReactSelect>
        </>
      )}
      {value && (
        <div
          className="remove-service-icon ps-4"
          onClick={handleRemove}
          style={{ cursor: "pointer" }}
        >
          <SVG src={close} className="logo-main" />
        </div>
      )}
    </>
  );
};
export default SelectDropdown;
