import React from "react";
import "../../templates/scss/temp02.scss";
import SVG from "react-inlinesvg";
import user01 from "../images/user01.png";
import call from "../images/icons/call.svg";
import map from "../images/icons/map.svg";
import envelop from "../images/icons/envelop.svg";
import location from "../images/icons/location.svg";
import whatsapp from "../images/icons/whatsapp.svg";
import instagram from "../images/icons/instagram.svg";
import youtube from "../images/icons/youtube.svg";
import twitter from "../images/icons/twitter.svg";
import facebook from "../images/icons/facebook.svg";
import shareNew from "../images/icons/shareNew.svg";
import reviewNew from "../images/icons/reviewNew.svg";
import contactadd from "../images/icons/contactadd.svg";
import loading from "../images/misc/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../setup";
import { parseUrl, socialSharing, tracker } from "../../app/helper/helper";
import { useEffect, useState } from "react";
import { TemplateUserDataRedux } from "../../app/modules/template/redux/ReduxDispatchExport";
import { ASSEST_URL, PUBLIC_URL, RAZORPAY_ENABLED } from "../../app/helper/EnvVar";
import { ReviewModal } from "../../app/pages/templates/components/Templates/common/ReviewModal";
import { SocialSharingModal } from "../../app/helper/SocialSharingModal";
import { Avatar } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { downloadVCFFile } from "../../downloadVCF";
import Skeleton from "react-loading-skeleton";
import PrintableModal from "../../components/PrintModal";
import TourPremiumFeatures from "../../components/tour-premium-features.tsx/TourPremiumFeatures";
import TemplateFooter from "../../components/template/TemplateFooter";
import { TemplateUserDataModel } from "../../app/modules/template/models/TemplateUserDataModel";
import { PROFILE_PREVIEW_TEMPLATE_VISIT, SHAREBLE_REVIEW_TEMP10_ICON_CLICK, SHAREBLE_SHARE_TEMP10_ICON_CLICK, SHAREBLE_URL_QR_CODE_TEMP10_ICON, SHAREBLE_URL_SOCIAL_TEMP10_FACEBOOK_ICON_CLICK, SHAREBLE_URL_SOCIAL_TEMP10_INSTAGRAM_ICON_CLICK, SHAREBLE_URL_SOCIAL_TEMP10_TWITTER_ICON_CLICK, SHAREBLE_URL_SOCIAL_TEMP10_WHATSAAP_ICON_CLICK, SHAREBLE_URL_SOCIAL_TEMP10_YOUTUBE_ICON_CLICK, SHARE_URL_EMAIL_LINK_TEMP10_CLICK, SHARE_URL_LOCATION_TEMP10_CLICK, SHARE_URL_PHONE_NUMBER_TEMP10_CLICK, SHARE_URL_WEBLINK_TEMP10_CLICK } from "../../app/helper/EventsConst";
import QRCodeComponent from "../../components/QRCode";

type Props = {
  profilePreview: unknown;
  companyLogoPreview: unknown;
  loader?: boolean;
  logoChangeLoading?: boolean;
  premium?: boolean;
  editMode?: boolean;
  skeletonLoading?: boolean;
  reacTour?: boolean;
  mobileUserData?: TemplateUserDataModel;
  changeTemplateColor?: string;  
  language?: string;
  templateId?: number;
  visitProfilePreview?:boolean;
};

// card template 10
const Template010 = ({
  profilePreview,
  companyLogoPreview,
  loader,
  logoChangeLoading,
  premium,
  editMode,
  skeletonLoading,
  reacTour,
  mobileUserData,
  changeTemplateColor,
  templateId,
  language,
  visitProfilePreview = false
}: Props) => {
  const dispatch = useDispatch();
  const {templateUserData, userTemplateByID} = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData
  );
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [fromEditTemplate, ] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [sloading , setsLoading] = useState(skeletonLoading)
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTourOpen, setIsTourOpen] = useState<boolean>();
  const [currentStep, setCurrentStep] = useState<number>();

  useEffect(()=>{
    if(visitProfilePreview == true){
      tracker({
        eventName: PROFILE_PREVIEW_TEMPLATE_VISIT, 
        dataInfo: {
          templateId: templateId,
          mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
          userName: (mobileUserData?.firstName ? mobileUserData?.firstName : templateUserData?.firstName) + " " + (mobileUserData?.lastName ? mobileUserData?.lastName : templateUserData?.lastName)
        },
      });
    }
  },[])

  useEffect(() => {
    if (RAZORPAY_ENABLED === "true") {
      if (templateUserData?.premium_plan === true || premium === true) {
        if(currentStep === undefined && isTourOpen !== false){
          if(reacTour !== false){
            setTimeout(() => {
              setIsTourOpen(true);
            }, 100);
          }
        }
      }
    }
  });

  useEffect(() => {
    if(((sloading || userTemplateByID === false) && fromEditTemplate === false) === true){
      setTimeout(() => {
        setsLoading(false);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if(templateUserData || mobileUserData){
      dispatch(
        TemplateUserDataRedux.actions.setTemplateUserData({
          ...templateUserData,
          templateColor: (mobileUserData ? changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor : templateUserData?.templateColor),
        })
        );
        setColor((mobileUserData ? changeTemplateColor ? changeTemplateColor :   mobileUserData?.templateColor : templateUserData?.templateColor));
    }
  }, [templateUserData?.selectedTemplateID, dispatch]);

  function setColor(newColor: string) {
    const color = newColor ? newColor : "#a29d06";
    document.documentElement.style.setProperty("--theme05", color);
    document.documentElement.style.setProperty(
      "--secondary05",
      templateUserData?.templateSecondaryColor || "#fffef5"
    );
  }

  const showReviewModal = () => {
    setReviewModal(!reviewModal);
    tracker({eventName:SHAREBLE_REVIEW_TEMP10_ICON_CLICK,dataInfo:{
      mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
      templateId:templateUserData?.selectedTemplateID,
    }})
  };

  const handleClose = () => {
    setShowFallback(!showFallback);
  };

  const shareButton = () => {
    socialSharing((mobileUserData ? mobileUserData : templateUserData), () => {
      setShowFallback(true);
      tracker({eventName:SHAREBLE_SHARE_TEMP10_ICON_CLICK,dataInfo:{
        mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
        templateId:templateUserData?.selectedTemplateID,
  
      }})
    });
  };

  const handleShow = () => {
    tracker({eventName:SHAREBLE_URL_QR_CODE_TEMP10_ICON,dataInfo:{
      mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
    }})
    setShowModal(!showModal);
  } 
  const handleCloseModal = () => setShowModal(!showModal);
  const handlePrint = () => {
    const contentElement = document.getElementById('printContent');

    if (contentElement) {
      const content = contentElement.innerHTML;
      const newWindow = window.open('', '_blank');

      if (newWindow) {
        newWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
            </head>
            <body>
              ${content}
            </body>
          </html>
        `);
        newWindow.document.close();
        newWindow.print();
      } else {
        console.error("Unable to open print window. Please check your browser settings.");
      }
    } else {
      console.error("Element with ID 'printContent' not found.");
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const buttonStyle: React.CSSProperties = {
    color: `${(mobileUserData ? mobileUserData?.templateColor : templateUserData?.templateColor)}`,
    border: `1px solid ${(mobileUserData ? mobileUserData?.templateColor : templateUserData?.templateColor)}`,
    borderRadius: "15px",
    marginLeft: "7px",
    padding: "0px 10px",
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
    transition: "background-color 0.3s",
  };

  const hoverStyle: React.CSSProperties = {
    backgroundColor: `${(mobileUserData ? mobileUserData?.templateColor : templateUserData?.templateColor)}`,
    color: "white",
  };

  const buttonStyles = isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle;

  const handleRemoveOverflowHidden = () => {
    document.body.classList.remove('overflow-hidden');
    const el = document.getElementById('navRef')
    el?.classList.remove("show-nav-mobile");
    const colorpickerElement = document.getElementById("color-inner-rounded")
    colorpickerElement?.classList.remove("pe-none")
    const templateButtonElement = document.getElementById("side-temp-preview")
    templateButtonElement?.classList.remove("pe-none")
  }
  const HandleTemp10WhatsaapClick = () => {
    tracker({
      eventName: SHAREBLE_URL_SOCIAL_TEMP10_WHATSAAP_ICON_CLICK,
      dataInfo: {
        mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
        templateId: templateUserData?.selectedTemplateID,
      },
    });
  };
  
  const HandleTemp10YoutubeClick = () => {
    tracker({
      eventName: SHAREBLE_URL_SOCIAL_TEMP10_YOUTUBE_ICON_CLICK,
      dataInfo: {
        mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
        templateId: templateUserData?.selectedTemplateID,
      },
    });
  };
  
  const HandleTemp10InstagramClick = () => {
    
    tracker({
      eventName: SHAREBLE_URL_SOCIAL_TEMP10_INSTAGRAM_ICON_CLICK,
      dataInfo: {
        mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
        templateId: templateUserData?.selectedTemplateID,
      },
    });
  };
  
  const HandleTemp10TwitterClick = () => {

    tracker({
      eventName: SHAREBLE_URL_SOCIAL_TEMP10_TWITTER_ICON_CLICK,
      dataInfo: {
        mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
        templateId: templateUserData?.selectedTemplateID,
      },
    });
  };
  
  const HandleTemp10FacebookClick = () => {

    tracker({
      eventName: SHAREBLE_URL_SOCIAL_TEMP10_FACEBOOK_ICON_CLICK,
      dataInfo: {
        mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
        templateId: templateUserData?.selectedTemplateID,
      },
    });
  };
  
  const HandleTemp10PhoneClick = () => {
    
    tracker({ eventName: SHARE_URL_PHONE_NUMBER_TEMP10_CLICK ,dataInfo:{
      mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
    }});
  };
  
  const HandleTemp10WeblinkClick = () => {

    tracker({ eventName: SHARE_URL_WEBLINK_TEMP10_CLICK,dataInfo:{
      mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
    } });
  };
  
  const HandleTemp10EmailClick = () => {

    tracker({ eventName: SHARE_URL_EMAIL_LINK_TEMP10_CLICK ,dataInfo:{
      mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
    }});
  };
  
  const HandleTemp10LocationClick = () => {

    tracker({ eventName: SHARE_URL_LOCATION_TEMP10_CLICK,dataInfo:{
      mobileNumber:mobileUserData?.mobileNumber ? mobileUserData?.mobileNumber : templateUserData?.mobileNumber,
    } });
  };

  return (
    <>
      <div className="template-main" onClick={handleRemoveOverflowHidden}>
      <TourPremiumFeatures
          isTourOpen={isTourOpen}
          setIsTourOpen={setIsTourOpen}
          setCurrentStep={setCurrentStep}
        />
        <div className="temp-inner">
          {mobileUserData ? <div className="temp-header bg-color p-0">
            {RAZORPAY_ENABLED === 'true' ? (mobileUserData?.premium_plan === true ||
              premium === true) && (mobileUserData?.isCompanyLogo
                ? <div className={`${mobileUserData?.logoShape === "circle"
                  ? "card-logo-rectangle"
                  : "card-logo-square"
                  } logo-bg-shape shape-left`}>
                  {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                    <Skeleton height={mobileUserData?.logoShape === "circle" ? 30 : 40} width={mobileUserData?.logoShape === "circle" ? 120 : 40} />
                  ) : (
                    <div className="logo-set">
                      {mobileUserData?.company_logo &&
                        loader ? (
                        <Avatar
                          src={loading}
                          alt="loding"
                          variant="square"
                          style={{ width: 60, height: 60 }}
                          className="ps-3 pe-3 pb-4 pt-4"
                        />) : (mobileUserData?.logoShape === "circle" ? (
                          logoChangeLoading ? (
                            <Avatar
                              src={loading}
                              alt="loding"
                              variant="square"
                              style={{ width: 60, height: 60 }}
                              className="ps-3 pe-3 pb-4 pt-4"
                            />
                          ) : <img
                            src={
                              mobileUserData?.company_logo
                                ? typeof mobileUserData?.company_logo != "string"
                                  ? `${ASSEST_URL}/${mobileUserData?.company_logo?.filename_disk}` ||
                                  (mobileUserData?.company_logo &&
                                    URL.createObjectURL(
                                      mobileUserData?.company_logo
                                    ))
                                  : mobileUserData?.company_logo
                                : mobileUserData?.company_logo &&
                                  URL.createObjectURL(
                                    mobileUserData?.company_logo
                                  )
                                  ? mobileUserData?.company_logo &&
                                  URL.createObjectURL(
                                    mobileUserData?.company_logo
                                  )
                                  : companyLogoPreview
                            }
                            className="logo-img p-3"
                            alt="logo"
                          />
                        ) : (
                          logoChangeLoading ? (
                            <Avatar
                              src={loading}
                              alt="loding"
                              variant="square"
                              style={{ width: 60, height: 60 }}
                              className="ps-3 pe-3 pb-4 pt-4"
                            />
                          ) : <Avatar
                            src={
                              mobileUserData?.company_logo
                                ? typeof mobileUserData?.company_logo != "string"
                                  ? `${ASSEST_URL}/${mobileUserData?.company_logo?.filename_disk}` ||
                                  (mobileUserData?.company_logo &&
                                    URL.createObjectURL(
                                      mobileUserData?.company_logo
                                    ))
                                  : mobileUserData?.company_logo
                                : mobileUserData?.company_logo &&
                                  URL.createObjectURL(
                                    mobileUserData?.company_logo
                                  )
                                  ? mobileUserData?.company_logo &&
                                  URL.createObjectURL(
                                    mobileUserData?.company_logo
                                  )
                                  : companyLogoPreview
                            }
                            variant="square"
                            alt="logo"
                            className="logo-img"
                          />
                        ))
                      }
                    </div>)}
                </div> : "") : (mobileUserData?.isCompanyLogo
                  ? <div className={`${mobileUserData?.logoShape === "circle"
                    ? "card-logo-rectangle"
                    : "card-logo-square"
                    } logo-bg-shape shape-left`}>
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton height={mobileUserData?.logoShape === "circle" ? 30 : 40} width={mobileUserData?.logoShape === "circle" ? 120 : 40} />
                    ) : (
                      <div className="logo-set">
                        {mobileUserData?.company_logo &&
                          loader ? (
                          <Avatar
                            src={loading}
                            alt="loding"
                            variant="square"
                            style={{ width: 60, height: 60 }}
                            className="ps-3 pe-3 pb-4 pt-4"
                          />) : (mobileUserData?.logoShape === "circle" ? (
                            logoChangeLoading ? (
                              <Avatar
                                src={loading}
                                alt="loding"
                                variant="square"
                                style={{ width: 60, height: 60 }}
                                className="ps-3 pe-3 pb-4 pt-4"
                              />
                            ) : <img
                              src={
                                mobileUserData?.company_logo
                                  ? typeof mobileUserData?.company_logo != "string"
                                    ? `${ASSEST_URL}/${mobileUserData?.company_logo?.filename_disk}` ||
                                    (mobileUserData?.company_logo &&
                                      URL.createObjectURL(
                                        mobileUserData?.company_logo
                                      ))
                                    : mobileUserData?.company_logo
                                  : mobileUserData?.company_logo &&
                                    URL.createObjectURL(
                                      mobileUserData?.company_logo
                                    )
                                    ? mobileUserData?.company_logo &&
                                    URL.createObjectURL(
                                      mobileUserData?.company_logo
                                    )
                                    : companyLogoPreview
                              }
                              className="logo-img p-3"
                              alt="logo"
                            />
                          ) : (
                            logoChangeLoading ? (
                              <Avatar
                                src={loading}
                                alt="loding"
                                variant="square"
                                style={{ width: 60, height: 60 }}
                                className="ps-3 pe-3 pb-4 pt-4"
                              />
                            ) : <Avatar
                              src={
                                mobileUserData?.company_logo
                                  ? typeof mobileUserData?.company_logo != "string"
                                    ? `${ASSEST_URL}/${mobileUserData?.company_logo?.filename_disk}` ||
                                    (mobileUserData?.company_logo &&
                                      URL.createObjectURL(
                                        mobileUserData?.company_logo
                                      ))
                                    : mobileUserData?.company_logo
                                  : mobileUserData?.company_logo &&
                                    URL.createObjectURL(
                                      mobileUserData?.company_logo
                                    )
                                    ? mobileUserData?.company_logo &&
                                    URL.createObjectURL(
                                      mobileUserData?.company_logo
                                    )
                                    : companyLogoPreview
                              }
                              variant="square"
                              alt="logo"
                              className="logo-img"
                            />
                          ))
                        }
                      </div>)}
                  </div> : "")}
            <div className="user-avtar-cols justify-content-start">
              <div className="cols name-cols w-100">
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                  <Skeleton width={163} height={34}/>
                ) :
                  <div className="user-name">
                    {mobileUserData?.firstName || "John"}{" "}
                    {mobileUserData?.lastName || "Doe"}
                  </div>}
                
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                  <Skeleton width={43} height={24} />
                ) : (
                  <>
                    {RAZORPAY_ENABLED === 'true' ?
                     (mobileUserData?.premium_plan === true ||
                      premium === true) &&
                      (
                        ((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (<Skeleton width={158} height={25} />
                        ) :
                  <div className="user-post mb-2">
                      {(mobileUserData?.designation || `Co-founder `)} 
                  </div>)
                      : 
                      (((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (<Skeleton width={158} height={25} />
                      ) :
                    <div className="user-post mb-2">
                      {(mobileUserData?.designation || `Co-founder `)}
                  </div>
                      )}
                    </>
                  )}
                  
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                 <Skeleton width={100} height={24} />
                ) : (
                    RAZORPAY_ENABLED === 'true' ? (mobileUserData?.premium_plan === true ||
                      premium === true) &&
                      (mobileUserData?.isCompanyName
                        ? 
                        <div className="company-name">
{                        mobileUserData?.companyName || "Infinity Aotumation"}
                          </div>            
                        : "") : (mobileUserData?.isCompanyName
                          ? <div className="company-name">
                          {mobileUserData?.companyName || "Infinity Aotumation"}
                          </div>                          
                          : "")
                  )}
              </div>
              <div className="cols">
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                   <Skeleton width={125} height={125} style={{ borderRadius: "75%", zIndex: 9999 }} />
                ) : (
                  <div className="user-img rounded mb-3">
                    {mobileUserData?.profile_image ? (
                      <Avatar
                        src={
                          mobileUserData?.profile_image
                            ? typeof mobileUserData?.profile_image != "string"
                              ? `${ASSEST_URL}/${mobileUserData?.profile_image?.filename_disk}` ||
                              (mobileUserData?.profile_image &&
                                URL.createObjectURL(
                                  mobileUserData?.profile_image
                                ))
                              : mobileUserData?.profile_image
                            : profilePreview
                        }
                        variant="circular"
                        alt="user-img"
                        style={{ width: "130px", height: "130px" }}
                      />
                    ) : (
                      <Avatar
                        src={user01}
                        variant="circular"
                        style={{ width: "130px", height: "130px" }}
                      />
                    )}
                  </div>)}
              </div>
            </div>
          </div> : <div className="temp-header bg-color p-0">
            {RAZORPAY_ENABLED === 'true' ? (templateUserData?.premium_plan === true ||
              premium === true) && (templateUserData?.isCompanyLogo
                ? <div className={`${templateUserData?.logoShape === "circle"
                  ? "card-logo-rectangle"
                  : "card-logo-square"
                  } logo-bg-shape shape-left`}>
                  {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                    <Skeleton height={templateUserData?.logoShape === "circle" ? 30 : 40} width={templateUserData?.logoShape === "circle" ? 120 : 40} />
                  ) : (
                    <div className="logo-set">
                      {templateUserData?.company_logo &&
                        loader ? (
                        <Avatar
                          src={loading}
                          alt="loding"
                          variant="square"
                          style={{ width: 60, height: 60 }}
                          className="ps-3 pe-3 pb-4 pt-4"
                        />) : (templateUserData?.logoShape === "circle" ? (
                          logoChangeLoading ? (
                            <Avatar
                              src={loading}
                              alt="loding"
                              variant="square"
                              style={{ width: 60, height: 60 }}
                              className="ps-3 pe-3 pb-4 pt-4"
                            />
                          ) : <img
                            src={
                              templateUserData?.company_logo
                                ? typeof templateUserData?.company_logo != "string"
                                  ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                  (templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    ))
                                  : templateUserData?.company_logo
                                : templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  )
                                  ? templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  )
                                  : companyLogoPreview
                            }
                            className="logo-img p-3"
                            alt="logo"
                          />
                        ) : (
                          logoChangeLoading ? (
                            <Avatar
                              src={loading}
                              alt="loding"
                              variant="square"
                              style={{ width: 60, height: 60 }}
                              className="ps-3 pe-3 pb-4 pt-4"
                            />
                          ) : <Avatar
                            src={
                              templateUserData?.company_logo
                                ? typeof templateUserData?.company_logo != "string"
                                  ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                  (templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    ))
                                  : templateUserData?.company_logo
                                : templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  )
                                  ? templateUserData?.company_logo &&
                                  URL.createObjectURL(
                                    templateUserData?.company_logo
                                  )
                                  : companyLogoPreview
                            }
                            variant="square"
                            alt="logo"
                            className="logo-img"
                          />
                        ))
                      }
                    </div>)}
                </div> : "") : (templateUserData?.isCompanyLogo
                  ? <div className={`${templateUserData?.logoShape === "circle"
                    ? "card-logo-rectangle"
                    : "card-logo-square"
                    } logo-bg-shape shape-left`}>
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton height={templateUserData?.logoShape === "circle" ? 30 : 40} width={templateUserData?.logoShape === "circle" ? 120 : 40} />
                    ) : (
                      <div className="logo-set">
                        {templateUserData?.company_logo &&
                          loader ? (
                          <Avatar
                            src={loading}
                            alt="loding"
                            variant="square"
                            style={{ width: 60, height: 60 }}
                            className="ps-3 pe-3 pb-4 pt-4"
                          />) : (templateUserData?.logoShape === "circle" ? (
                            logoChangeLoading ? (
                              <Avatar
                                src={loading}
                                alt="loding"
                                variant="square"
                                style={{ width: 60, height: 60 }}
                                className="ps-3 pe-3 pb-4 pt-4"
                              />
                            ) : <img
                              src={
                                templateUserData?.company_logo
                                  ? typeof templateUserData?.company_logo != "string"
                                    ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                    (templateUserData?.company_logo &&
                                      URL.createObjectURL(
                                        templateUserData?.company_logo
                                      ))
                                    : templateUserData?.company_logo
                                  : templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    )
                                    ? templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    )
                                    : companyLogoPreview
                              }
                              className="logo-img p-3"
                              alt="logo"
                            />
                          ) : (
                            logoChangeLoading ? (
                              <Avatar
                                src={loading}
                                alt="loding"
                                variant="square"
                                style={{ width: 60, height: 60 }}
                                className="ps-3 pe-3 pb-4 pt-4"
                              />
                            ) : <Avatar
                              src={
                                templateUserData?.company_logo
                                  ? typeof templateUserData?.company_logo != "string"
                                    ? `${ASSEST_URL}/${templateUserData?.company_logo?.filename_disk}` ||
                                    (templateUserData?.company_logo &&
                                      URL.createObjectURL(
                                        templateUserData?.company_logo
                                      ))
                                    : templateUserData?.company_logo
                                  : templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    )
                                    ? templateUserData?.company_logo &&
                                    URL.createObjectURL(
                                      templateUserData?.company_logo
                                    )
                                    : companyLogoPreview
                              }
                              variant="square"
                              alt="logo"
                              className="logo-img"
                            />
                          ))
                        }
                      </div>)}
                  </div> : "")}
            <div className="user-avtar-cols justify-content-start">
              <div className="cols name-cols w-100">
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                  <Skeleton width={163} height={34}/>
                ) :
                  <div className="user-name">
                    {templateUserData?.firstName || "John"}{" "}
                    {templateUserData?.lastName || "Doe"}
                  </div>}
                
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                  <Skeleton width={43} height={24} />
                ) : (
                  <>
                    {RAZORPAY_ENABLED === 'true' ? (templateUserData?.premium_plan === true ||
                      premium === true) &&

                      (
                        ((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (<Skeleton width={158} height={25} />
                        ) : <div className="user-post mb-2">
                          {templateUserData?.designation ||
                            `Co-founder`}
                        </div>
                      )
                      : 
                    <div className="user-post mb-2">
                      {(templateUserData?.designation || `Co-founder `)}
                  </div>}
                    </>
                  )}
                  
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                  <Skeleton width={100} height={24} />
                ) : (
                    RAZORPAY_ENABLED === 'true' ? (templateUserData?.premium_plan === true ||
                      premium === true) &&
                      (templateUserData?.isCompanyName
                        ? 
                        <div className="company-name">
{                        templateUserData?.companyName || "Infinity Aotumation"}
                          </div>            
                        : "") : (templateUserData?.isCompanyName
                          ? <div className="company-name">
                          {templateUserData?.companyName || "Infinity Aotumation"}
                          </div>                          
                          : "")
                  )}
              </div>
              <div className="cols">
                {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                  <Skeleton width={125} height={125} style={{ borderRadius: "75%", zIndex: 9999 }} />
                ) : (
                  <div className="user-img rounded mb-3">
                    {templateUserData?.profile_image ? (
                      <Avatar
                        src={
                          templateUserData?.profile_image
                            ? typeof templateUserData?.profile_image != "string"
                              ? `${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}` ||
                              (templateUserData?.profile_image &&
                                URL.createObjectURL(
                                  templateUserData?.profile_image
                                ))
                              : templateUserData?.profile_image
                            : profilePreview
                        }
                        variant="circular"
                        alt="user-img"
                        style={{ width: "130px", height: "130px" }}
                      />
                    ) : (
                      <Avatar
                        src={user01}
                        variant="circular"
                        style={{ width: "130px", height: "130px" }}
                      />
                    )}
                  </div>)}
              </div>
            </div>
          </div>}

          <div className="temp-lower">
            {mobileUserData ? <ul className="list-unstyled d-flex service-list margin-top-reverse">
              {mobileUserData?.service1?.name ? (
                <li className="item" key={mobileUserData?.service1?.id}>
                  <div className="ser-link">
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={40} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${mobileUserData?.service1?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {mobileUserData?.service1?.name}
                      </span>)}
                  </div>
                </li>
              ) : (
                ""
              )}
              {mobileUserData?.service2?.name ? (
                <li className="item" key={mobileUserData?.service2?.id}>

                  <div className="ser-link">
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={40} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${mobileUserData?.service2?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {mobileUserData?.service2?.name}
                      </span>)}
                  </div>
                </li>
              ) : (
                ""
              )}
              {mobileUserData?.service3?.name ? (
                <li className="item" key={mobileUserData?.service3?.id}>

                  <div className="ser-link">

                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={40} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${mobileUserData?.service3?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {mobileUserData?.service3?.name}
                      </span>)}
                  </div>
                </li>
              ) : (
                ""
              )}
              {mobileUserData?.service4?.name ? (
                <li className="item" key={mobileUserData?.service4?.id}>

                  <div className="ser-link">
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={30} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${mobileUserData?.service4?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>
                    )}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {mobileUserData?.service4?.name}
                      </span>)}
                  </div>

                </li>
              ) : (
                ""
              )}
            </ul> : <ul className="list-unstyled d-flex service-list margin-top-reverse">
              {templateUserData?.service1?.name ? (
                <li className="item" key={templateUserData?.service1?.id}>
                  <div className="ser-link">
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={40} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${templateUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${templateUserData?.service1?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {templateUserData?.service1?.name}
                      </span>)}
                  </div>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.service2?.name ? (
                <li className="item" key={templateUserData?.service2?.id}>

                  <div className="ser-link">
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={40} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${templateUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${templateUserData?.service2?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {templateUserData?.service2?.name}
                      </span>)}
                  </div>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.service3?.name ? (
                <li className="item" key={templateUserData?.service3?.id}>

                  <div className="ser-link">

                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={40} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${templateUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${templateUserData?.service3?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {templateUserData?.service3?.name}
                      </span>)}
                  </div>
                </li>
              ) : (
                ""
              )}
              {templateUserData?.service4?.name ? (
                <li className="item" key={templateUserData?.service4?.id}>

                  <div className="ser-link">
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={40} height={30} style={{ borderRadius: "25px" }} />
                    ) : (
                      <div
                        className="icon"
                        style={{
                          background: `${templateUserData?.templateColor || "#a29d06"
                            }`,
                          padding: "7px",
                          borderRadius: "30px",
                          height: "40px",
                          width: "40px",
                          maxWidth: "40px",
                        }}
                      >
                        <SVG
                          src={`${ASSEST_URL}/${templateUserData?.service4?.svg?.filename_disk}`}
                          fill="white"
                          style={{ height: "23.434", width: "26.071" }}
                        />
                      </div>
                    )}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={55} height={15} />
                    ) : (
                      <span className="service-name">
                        {templateUserData?.service4?.name}
                      </span>)}
                  </div>

                </li>
              ) : (
                ""
              )}
            </ul>}
            {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
              <Skeleton width={300} height={20} style={{ marginLeft: "40px",marginTop:"40px" }} />
            ) : (
              <p
                className="desc text-center mb-4"
                dangerouslySetInnerHTML={{
                  __html:
                    (mobileUserData ? mobileUserData?.description?.replace(/\n/g, "</br>") : templateUserData?.description?.replace(/\n/g, "</br>")) ||
                    `Award Winning web-developer with 7 years of experience in HTML
              ,CSS, LAMP, My main interests are object-oriented and
              user-centered design.`,
                }}
              />)}

            <div className="d-flex position-relative justify-content-between">
              {mobileUserData ? <ul className="contact-detail mb-0">
                <li className="item">
                  <a
                    href={`tel:${mobileUserData?.mobileNumber
                      ? mobileUserData?.mobileNumber
                      : "#"
                      }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={20} height={20} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={call}
                          fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                        />
                      </span>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={107} height={20} style={{ marginLeft: "10px"}} />
                    ) : (
                      <span className="link-text">
                        {mobileUserData?.mobileNumber
                          ? mobileUserData?.mobileNumber
                          : `+91 9999999999`}
                      </span>)}
                  </a>
                </li>
                {mobileUserData?.isWebsite ? (
                  <li className="item">
                    <a
                      href={`${mobileUserData?.websiteLink
                        ? parseUrl(mobileUserData?.websiteLink)
                        : ""
                        }`}
                      target={"_blank"}
                      className="contact-link"
                      rel="noreferrer"
                    >
                      {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                        <Skeleton width={20} height={20} />
                      ) : (
                        <span className="icon">
                          <SVG
                            src={map}
                            fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                          />
                        </span>)}
                      {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                        <Skeleton width={107} height={20} style={{ marginLeft: "10px" }} />
                      ) : (
                        <span className="link-text">
                          {mobileUserData?.websiteLink
                            ? mobileUserData?.websiteLink.replace(
                              /(^\w+:|^)\/\//,
                              ""
                            )
                            : `www.mobilevisit.com`}
                        </span>)}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="item">
                  <a
                    href={`mailto:${mobileUserData?.email ? mobileUserData?.email : ""
                      }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={20} height={20} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={envelop}
                          fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                        />
                      </span>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={107} height={20} style={{ marginLeft: "10px" }} />
                    ) : (
                      <span className="link-text">
                        {mobileUserData?.email
                          ? mobileUserData?.email
                          : `youremailid@somedomain.com`}
                      </span>)}
                  </a>
                </li>
                <li className="item">
                  <a
                    href={`http://maps.google.com/?q=${mobileUserData?.address
                      ? mobileUserData?.address
                      : `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India - 395009`
                      }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                  >
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={20} height={20} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={location}
                          fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                        />
                      </span>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={107} height={20} style={{ marginLeft: "10px" }} />
                    ) : (
                      <span className="link-text">
                        {mobileUserData?.address ||
                          `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India -
                          395009`}
                      </span>)}
                  </a>
                </li>
              </ul> : <ul className="contact-detail mb-0">
                <li className="item">
                  <a
                    href={`tel:${templateUserData?.mobileNumber
                      ? templateUserData?.mobileNumber
                      : "#"
                      }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                    onClick={HandleTemp10PhoneClick}
                  >
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={20} height={20} style={{ marginTop: "20px" }} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={call}
                          fill={templateUserData?.templateColor || "#a29d06"}
                        />
                      </span>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={107} height={20} style={{ marginLeft: "10px", marginTop: "20px" }} />
                    ) : (
                      <span className="link-text">
                        {templateUserData?.mobileNumber
                          ? templateUserData?.mobileNumber
                          : `+91 9999999999`}
                      </span>)}
                  </a>
                </li>
                {templateUserData?.isWebsite ? (
                  <li className="item">
                    <a
                      href={`${templateUserData?.websiteLink
                        ? parseUrl(templateUserData?.websiteLink)
                        : ""
                        }`}
                      target={"_blank"}
                      className="contact-link"
                      rel="noreferrer"
                      onClick={HandleTemp10WeblinkClick}
                    >
                      {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                        <Skeleton width={20} height={20} />
                      ) : (
                        <span className="icon">
                          <SVG
                            src={map}
                            fill={templateUserData?.templateColor || "#a29d06"}
                          />
                        </span>)}
                      {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                        <Skeleton width={107} height={20} style={{ marginLeft: "10px" }} />
                      ) : (
                        <span className="link-text">
                          {templateUserData?.websiteLink
                            ? templateUserData?.websiteLink.replace(
                              /(^\w+:|^)\/\//,
                              ""
                            )
                            : `www.mobilevisit.com`}
                        </span>)}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li className="item">
                  <a
                    href={`mailto:${templateUserData?.email ? templateUserData?.email : ""
                      }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                    onClick={HandleTemp10EmailClick}
                  >
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={20} height={20} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={envelop}
                          fill={templateUserData?.templateColor || "#a29d06"}
                        />
                      </span>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={107} height={20} style={{ marginLeft: "10px" }} />
                    ) : (
                      <span className="link-text">
                        {templateUserData?.email
                          ? templateUserData?.email
                          : `youremailid@somedomain.com`}
                      </span>)}
                  </a>
                </li>
                <li className="item">
                  <a
                    href={`http://maps.google.com/?q=${templateUserData?.address
                      ? templateUserData?.address
                      : `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India - 395009`
                      }`}
                    target={"_blank"}
                    className="contact-link"
                    rel="noreferrer"
                    onClick={HandleTemp10LocationClick}
                  >
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={20} height={20} />
                    ) : (
                      <span className="icon">
                        <SVG
                          src={location}
                          fill={templateUserData?.templateColor || "#a29d06"}
                        />
                      </span>)}
                    {((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                      <Skeleton width={107} height={20} style={{ marginLeft: "10px" }} />
                    ) : (
                      <span className="link-text">
                        {templateUserData?.address ||
                          `425, Shiven Square, Pal,Adajan, Surat, Gujarat, India -
                          395009`}
                      </span>)}
                  </a>
                </li>
              </ul>}

              {mobileUserData ? <div className="d-flex flex-column text-end">
                {(
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (<Skeleton width={70} height={70} style={{ marginBottom: "5px", marginTop: "50px" }} />) : (
                      <>
                        <span className="qr-icon bordered mt-1 mb-2" onClick={handleShow}>
                          <QRCodeComponent value={`${PUBLIC_URL}/${mobileUserData?.mobileNumber}`}/>
                        </span>
                        <PrintableModal
                          showModal={showModal}
                          handleCloseModal={handleCloseModal}
                          templateUserData={mobileUserData}
                          handlePrint={handlePrint}
                        />
                      </>
                    ))
                )}

                {(
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                    <Skeleton width={70} height={31} />
                  ) : (
                    <div className="d-flex justify-content-center text-center">
                      <button
                        type="button"
                        className="addcontact-btn"
                        aria-label="addcontact-btn"
                        onClick={() => downloadVCFFile(mobileUserData)}
                      >
                        <SVG src={contactadd} width="38" height="20" />
                      </button>
                    </div>))
                )}

              </div> : <div className="d-flex flex-column text-end">
                {!editMode && (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (<Skeleton width={70} height={70} style={{ marginBottom: "5px", marginTop: "50px" }} />) : (
                      <>
                        <span className="qr-icon bordered mt-1 mb-2" onClick={handleShow}>
                          <QRCodeComponent value={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}/>
                        </span>
                        <PrintableModal
                          showModal={showModal}
                          handleCloseModal={handleCloseModal}
                          templateUserData={templateUserData}
                          handlePrint={handlePrint}
                        />
                      </>
                    ))
                )}

                {!editMode && (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ? (
                    <Skeleton width={70} height={31} />
                  ) : (
                    <div className="d-flex justify-content-center text-center">
                      <button
                        type="button"
                        className="addcontact-btn"
                        aria-label="addcontact-btn"
                        onClick={() => downloadVCFFile(templateUserData)}
                      >
                        <SVG src={contactadd} width="38" height="20" />
                      </button>
                    </div>))
                )}

              </div>}
            </div>

            <div className="social-media-wrap">
              {mobileUserData ? <ul className="social-media">
                {mobileUserData?.isWhatsapp ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                      ) : (
                      <li className="item">
                        <a
                          href={`https://wa.me/${mobileUserData?.whatsAppLink}`}
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                        >
                          <SVG
                            src={whatsapp}
                            stroke={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                            fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {mobileUserData?.isYouTube ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            mobileUserData?.youTubeLink
                              ? parseUrl(mobileUserData?.youTubeLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                        >
                          <SVG
                            src={youtube}
                            stroke={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                            fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {mobileUserData?.isInstagram ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            mobileUserData?.instagramLink
                              ? parseUrl(mobileUserData?.instagramLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                        >
                          <SVG
                            src={instagram}
                            stroke={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                            fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {mobileUserData?.isTwitter ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            mobileUserData?.twitterLink
                              ? parseUrl(mobileUserData?.twitterLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                        >
                          <SVG
                            src={twitter}
                            stroke={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                            fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {mobileUserData?.isFacebook ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px" ,marginLeft:"5px"}} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            mobileUserData?.facebookLink
                              ? parseUrl(mobileUserData?.facebookLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                        >
                          <SVG
                            src={facebook}
                            stroke={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                            fill={changeTemplateColor ? changeTemplateColor :  mobileUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
              </ul> : <ul className="social-media">
                {templateUserData?.isWhatsapp ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={`https://wa.me/${templateUserData?.whatsAppLink}`}
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                          onClick={HandleTemp10WhatsaapClick}
                        >
                          <SVG
                            src={whatsapp}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                            fill={templateUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {templateUserData?.isYouTube ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            templateUserData?.youTubeLink
                              ? parseUrl(templateUserData?.youTubeLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                          onClick={HandleTemp10YoutubeClick}
                        >
                          <SVG
                            src={youtube}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                            fill={templateUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {templateUserData?.isInstagram ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px" ,marginLeft:"5px"}} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            templateUserData?.instagramLink
                              ? parseUrl(templateUserData?.instagramLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                          onClick={HandleTemp10InstagramClick}
                        >
                          <SVG
                            src={instagram}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                            fill={templateUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {templateUserData?.isTwitter ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            templateUserData?.twitterLink
                              ? parseUrl(templateUserData?.twitterLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                          onClick={HandleTemp10TwitterClick}
                        >
                          <SVG
                            src={twitter}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                            fill={templateUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
                {templateUserData?.isFacebook ? (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <a
                          href={
                            templateUserData?.facebookLink
                              ? parseUrl(templateUserData?.facebookLink)
                              : ""
                          }
                          target={"_blank"}
                          className="social-link"
                          rel="noreferrer"
                          onClick={HandleTemp10FacebookClick}
                        >
                          <SVG
                            src={facebook}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                            fill={templateUserData?.templateColor || "#a29d06"}
                          />
                        </a>
                      </li>))
                ) : (
                  ""
                )}
              </ul>}
              <ul className="list-unstyled m-0 p-0 share-icon-wrap">
                {!editMode && (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px" ,marginLeft:"5px"}} />
                    ) : (
                      <li className="item">
                        <button
                          type="button"
                          aria-label="share button"
                          className="social-link share-button"
                          style={{ borderWidth: "0px", background: "transparent" }}
                          onClick={shareButton}
                        >
                          <SVG
                            src={shareNew}
                            style={{ width: "30px", height: "30px" }}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                          />
                        </button>
                      </li>))
                )}
                {!editMode && (
                  (((sloading || userTemplateByID === false) && fromEditTemplate === false) ?
                    (
                      <Skeleton width={34} height={34} style={{ borderRadius: "25px", marginTop: "0px",marginLeft:"5px" }} />
                    ) : (
                      <li className="item">
                        <button
                          type="button"
                          aria-label="review button"
                          onClick={() => {
                            showReviewModal();
                          }}
                          style={{ borderWidth: "0px", background: "transparent" }}
                          className="social-link review-button"
                        >
                          <SVG
                            src={reviewNew}
                            style={{ width: "30px", height: "30px" }}
                            stroke={templateUserData?.templateColor || "#a29d06"}
                          />
                        </button>
                      </li>))
                )}

              </ul>
            </div>            
              {!editMode && <TemplateFooter
                sloading={sloading}
                mobileUserData={mobileUserData}
                buttonStyles={buttonStyles}
                editMode={editMode}
                fromEditTemplate={fromEditTemplate}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                userTemplateByID={userTemplateByID}
                language={language}
              />}

          </div>

        </div>
      </div>
      {reviewModal && (
        <ReviewModal show={reviewModal} handleClose={showReviewModal} />
      )}
      {showFallback && (
        <SocialSharingModal
          show={showFallback}
          handleClose={handleClose}
        ></SocialSharingModal>
      )}
    </>
  );
};
export default Template010;