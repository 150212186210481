import SVG from "react-inlinesvg";
import React, { useState } from "react";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import InputField from "./edit-template/InputField";
import facebook from "../templates/images/facebook.svg";
import twitter from "../templates/images/twitter.svg";
import {
  WhatsappIcon, 
} from "react-share";
import {
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../setup";

import copyIcon from "../templates/images/icons/copy.svg";
import { PUBLIC_URL } from "../app/helper/EnvVar";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormattedMessage } from "react-intl";
import { SHARE_MODAL_SHARE_URL_CLICK, SHARE_MODAL_SOCIAL_ICON_facebook_CLICK, SHARE_MODAL_SOCIAL_ICON_twitter_CLICK, SHARE_MODAL_SOCIAL_ICON_Whatsap_CLICK, SHARE_MODAL_VIEW_URL_CLICK } from "../app/helper/EventsConst";
import { tracker } from "../app/helper/helper";

const ShareModal = (
  props: JSX.IntrinsicAttributes &
    Omit<
      Pick<
        React.DetailedHTMLProps<
          React.HTMLAttributes<HTMLDivElement>,
          HTMLDivElement
        >,
        "key" | keyof React.HTMLAttributes<HTMLDivElement>
      > & {
        ref?:
          | ((instance: HTMLDivElement | null) => void)
          | React.RefObject<HTMLDivElement>
          | null
          | undefined;
      },
      BsPrefixProps<"div"> & ModalProps
    > &
    BsPrefixProps<"div"> &
    ModalProps & { children?: React.ReactNode} | { userMobileNumber?: string }
) => {
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );
  const mobileNumber = props.userMobileNumber;
  const [newLocation , setNewLocation] = useState('')
  // const hanldeTwitterSharing = () => {  
  //   const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  //   let newLocation;
  //   if (iOS) {
  //     const scheme = "twitter";
  //     const url = `${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`
  //     const openURL = window.location.href=`${url}`;
  //     newLocation = scheme + ":" + openURL;
  //     setNewLocation(scheme + ":" + openURL)
  //     window.location.replace(newLocation);
  //   } else{
  //     setNewLocation('Android')
  //   }
  // }
  const copyText = `\nMy Services: \n\n ${templateUserData?.service1?.name ? `${templateUserData?.service1?.name}\n` : ''}${templateUserData?.service2?.name ? `${templateUserData?.service2?.name}\n` : ''}${templateUserData?.service3?.name ? `${templateUserData?.service3?.name}\n` : ''}${templateUserData?.service4?.name ? `${templateUserData?.service4?.name}\n` : ''}`;

  const handleTwitterSharing = () => {
    // const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent); // Check for iOS devices
    const baseTwitterUrl = "https://twitter.com/intent/tweet";
    
    // // Construct the tweet URL
    const urlToShare = `${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`;
    const textToShare = `${copyText}\n`;
    const twitterShareUrl = `${baseTwitterUrl}?text=${encodeURIComponent(textToShare)}&url=${encodeURIComponent(urlToShare)}`;
    
    // if (iOS) {
    //   // Open the Twitter share URL
    //   // window.location.href = twitterShareUrl;
    // } else {
    //   // For non-iOS devices, also open the Twitter share URL
    //   window.location.href = twitterShareUrl;
    // }
    // const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
    window.open(twitterShareUrl, '_blank');
  };
  const HandleViewUrlClick=()=>{
    tracker({eventName:SHARE_MODAL_VIEW_URL_CLICK,dataInfo:{
      mobileNumber:templateUserData?.mobileNumber
    }})
  }
  const HandleCopyIconClick =()=>{
    tracker({eventName:SHARE_MODAL_SHARE_URL_CLICK, dataInfo: {
      mobileNumber: templateUserData?.mobileNumber,
    }})
  }
  const HandleWhatsaapShareClick =()=>{
    tracker({eventName:SHARE_MODAL_SOCIAL_ICON_Whatsap_CLICK,dataInfo: {
      mobileNumber: templateUserData?.mobileNumber,
    }})
  }
  const HandleTwitterShareClick =()=>{
    tracker({eventName:SHARE_MODAL_SOCIAL_ICON_twitter_CLICK,dataInfo: {
      mobileNumber: templateUserData?.mobileNumber,
    }})
  }
  const HandleFacebookShareClick =()=>{
    
    const urlToShare = `${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
    window.open(facebookUrl, '_blank');
    tracker({eventName:SHARE_MODAL_SOCIAL_ICON_facebook_CLICK,dataInfo: {
      mobileNumber: templateUserData?.mobileNumber,
    }})
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="share-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="preview.share" defaultMessage={"Share"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="social-sharing">
            <ul className="social-media mb-4 pb-4">
              <li className="item">
                <a href={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`} target={"_blank"} className="social-link whats-app" rel="noreferrer" onClick={HandleWhatsaapShareClick}>
                  <WhatsappShareButton
                    url={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber} \n ${copyText}`}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </a>
                <span>
                  <FormattedMessage 
                  id="edit.share.template.whatsapp"
                  defaultMessage={"Whatsapp"}
                  />
                </span>
              </li>
             
              <li className="item" onClick={handleTwitterSharing}>
              {/* <li className="item"> */}
                {/* <a
                  href={newLocation === 'Android' ? `https://twitter.com/intent/tweet?url=${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber} ` : `https://twitter.com/intent/tweet?url=${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber} `}
                  target={"_blank"}
                  className="social-link twitter"
                  rel="noreferrer"
                  onClick={HandleTwitterShareClick}
                > */}
                <span className="mb-2" style={{cursor:'pointer'}}>
                  <SVG src={twitter} />
                </span>
                {/* </a> */}
                <span> 
                  <FormattedMessage
                  id="edit.share.template.twitter"
                  defaultMessage={"Twitter"}
                  />
                </span>
              </li>
              <li className="item">
                <a
                  href={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  target={"_blank"}
                  className="social-link facebook"
                  rel="noreferrer"
                  onClick={HandleFacebookShareClick}
                >
                  <FacebookShareButton
                    url={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber} \n ${copyText}`}                    
                  >
                    <SVG src={facebook} />
                  </FacebookShareButton>
                </a>
                <span>
                  <FormattedMessage
                  id="edit.share.template.facebook"
                  defaultMessage={"Facebook"}
                  />
                </span>
              </li>
            </ul>
          </div>
          <div className="sharelink-col">
            <b className="mb-2 d-flex">
              <FormattedMessage id="share.modal.view.URL" defaultMessage={"View Url"} />
            </b>
              <div className="position-relative" style={{ marginBottom: "20px" }}>
                <span className="mb-3 pe-10"> 
                  <a href={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`} target="_blank" rel="noreferrer" onClick={HandleViewUrlClick} >
                    {`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  </a>
                </span>
              </div>
          </div>
          <div className="sharelink-col">          
            <b className="mb-3 d-flex">
              <FormattedMessage id="share.modal.share.URL" defaultMessage={"Share Url"} />
            </b>
            <CopyToClipboard
              onCopy={() => {
                toast.success(<FormattedMessage id="share.modal.copy.clipboard" defaultMessage={"Copied to Clipboard!"} />);
              }}
              options={{ message: "Copied to Clipboard!" }}
              text={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData && templateUserData?.mobileNumber} \n ${copyText}`}
            >
              <div className="position-relative">
                <InputField
                  type="copy"
                  className="mb-3 pe-5"
                  placeholder=" "
                  value={`${PUBLIC_URL}/${mobileNumber ? mobileNumber : templateUserData?.mobileNumber}`}
                  autoFocus={false}
                  isDisabled={true}
                />
                <div className="copy-icon">
                  <button type="button" className="btn" onClick={HandleCopyIconClick}>
                    <SVG src={copyIcon} />
                  </button>
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
