import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SVG from "react-inlinesvg";
import Buttons from "./Buttons";
import "../scss/header.scss";
import { useLocation } from "react-router-dom";
import { RootState } from "../setup";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import preview from "../templates/images/icons/eye.svg";
import userIcon from "../templates/images/icons/user-icon.svg";
import logout from "../templates/images/icons/logout.svg";
import { Avatar } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link"; 
import { ASSEST_URL, TRANSLATION_ENABLED } from "../app/helper/EnvVar";
import Skeleton from "react-loading-skeleton";
import { FormattedMessage } from "react-intl";
import { Context } from "../wrapper/Wrapper";
import { actions } from "../app/modules/auth";
import { tracker } from "../app/helper/helper";
import { HEADER_ALL_TAB_CLICK, HEADER_LANGUAGE_CHANGE } from "../app/helper/EventsConst";

function Navigation() {
    const dispatch = useDispatch()
  const { locale, selectLang } = useContext(Context)

  const [loading,setLoading]=useState(false)
  useEffect(()=>{
    const timer=setTimeout(() => {
      setLoading(false);
    }, 2000)
    return ()=> clearTimeout(timer);
  },[])
  const navRef = React.useRef<HTMLElement | null>();
  const location = useLocation();
  const onToggleClick = (hashUrl?:string) => {
    if (navRef.current) {
    navRef.current.classList.toggle("show-nav");
    tracker({eventName:HEADER_ALL_TAB_CLICK, dataInfo:{
      tabName: hashUrl
    }});

  } else {
    console.error("navRef.current is null or undefined");
  }
}
  const {isAuthorized }= useSelector(
    (state: RootState) => state.auth
  );
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );  

  const HandleStepsScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, targetId: string) => {
    e.preventDefault();
    setTimeout(() => {
      const targetElement = document.getElementById(targetId)
      if (targetElement) {
        const offset = 65;
        window.scrollTo({
          top: targetElement.offsetTop - offset,
          behavior: 'smooth'
        })
        
        const navLinks = document.querySelectorAll('.nav-link');
        navLinks.forEach((link) => link.classList.remove('active'));
        const correspondingLink = document.getElementById('PS-Navlink-steps');
        
        if (correspondingLink) {
          correspondingLink.classList.add('active');
        }
      navLinks.forEach((links)=>{
        links.addEventListener('click',()=>{
          navLinks.forEach((navLink)=>navLink.classList.remove('active'));
          links.classList.add('active');
        })
      })
        window.history.pushState(null, "", `/#${targetId}`)
        window.location.hash = `#${targetId}`
      }
    })
  }

  return (

    <Navbar className="custom-navbar" expand="lg">
      {loading ? (
            <div style={{ width: '100%' }}>
            <Skeleton height={90} baseColor="#ebebeb" highlightColor="#d3d3d3 " />
          </div>
          ):(
      <Container>
        <Link to="/home" className="navbar-brand" aria-label="home">
          <SVG src="./assets/images/logo.svg" className="logo-main" />
        </Link>
        <button
          type="button"
          className="btn btn-nav p-0"
          onClick={onToggleClick}
        >
          <div className="menu_icon">
            <span className="one"></span>
            <span className="two"></span>
            <span className="three"></span>
          </div>
        </button>

        <Navbar.Collapse id="basic-navbar-nav" ref={navRef}>
          
            <Nav className="ms-lg-auto d-flex align-items-center">
            <div className="d-block d-lg-none position-relative w-100 text-center mb-4">
              <button
                type="button"
                className="btn btn-nav p-0 side-close-btn"
                onClick={onToggleClick}
              >
                <div className="close-icon">
                  <SVG
                    src="./assets/images/icons/close.svg"
                    className="close"
                  />
                </div>
              </button>
              <Nav.Link href="/home" className="pt-0">
                <SVG src="./assets/images/logo.svg" className="logo-main" />
              </Nav.Link>
            </div>
            <Link to={location?.pathname !== "/home" ? `/#home` : "#home"}>
              <span className={`nav-link ${location.hash === "#home" ? "active":""}`} onClick={() => onToggleClick(location.hash)}>
                <FormattedMessage id="home.home" defaultMessage={"Home"}/>
              </span>
            </Link>
            <Link
              to={location?.pathname !== "/home" ? `/#steps` : "#steps"} onClick={(e) => HandleStepsScroll(e, "steps")}>
              <span id="PS-Navlink-steps" className={`nav-link ${location.hash === "#steps" ? "active":"" }`} onClick={() => onToggleClick(location.hash)}>
                <FormattedMessage id="home.steps" defaultMessage={"Steps"}/>
              </span>
            </Link>
            <Link
              to={location?.pathname !== "/home" ? `/#video` : "#video"}
            >
              <span className={`nav-link ${location.hash === "#video" ? "active":"" }`} onClick={() => onToggleClick(location.hash)}>
                <FormattedMessage id="home.howto" defaultMessage={"How to"}/>
              </span>
            </Link>
            <Link
              to={
                location?.pathname !== "/home"
                  ? `/#business-cards`
                  : "#business-cards"
              }
            >
              <span className={`nav-link ${location.hash === "#business-cards" ? "active":"" }`} onClick={() => onToggleClick(location.hash)}>
              <FormattedMessage id="home.cards" defaultMessage={"Cards"}/>
              </span>
            </Link>
            <Link
              to={
                location?.pathname !== "/home" ? `/#contacts` : "#contacts"
              }
            >
              <span  className={`nav-link ${location.hash === "#contacts" ? "active":"" }`} onClick={() => onToggleClick(location.hash)}>
                <FormattedMessage id="home.contact" defaultMessage={"Contact"}/>
              </span>
            </Link>

            {TRANSLATION_ENABLED === "true" && <div className="language-selection">
              <form action="#">
                <label htmlFor="language" className="sr-only carate"></label>
                <select
                  name="language"
                  id="language"
                  className="select-language"
                  value={locale}
                  aria-label="selectLanguage"
                  onChange={(e) => {
                    selectLang(e)
                    dispatch(actions.setLanguage(e.target.value))
                    tracker({
                      eventName:HEADER_LANGUAGE_CHANGE , 
                      dataInfo: {
                        selectedLanguage: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="en">English</option>
                  <option value="hi">हिन्दी</option>
                </select>
              </form>
            </div>}

            {isAuthorized ? (
              <div className="btn-groups drop-items ">
                <Dropdown
                  as={ButtonGroup}
                  className="align-items-center d-none d-lg-flex"
                >
                  <div className="img-wrap mx-auto">
                    <div className=" position-relative me-1">
                      <Avatar
                        src={`${ASSEST_URL}/${templateUserData?.profile_image?.filename_disk}`}
                        style={{ width: "34px", height: "34px" }}
                      />
                    </div>
                  </div>

                  <Dropdown.Toggle
                    split
                    variant="success"
                    id="dropdown-split-basic"
                    className="p-0"
                  />

                  <Dropdown.Menu className="py-2">
                    <Dropdown.Item
                      className="user-p d-flex p-0"
                      onClick={onToggleClick}
                    >
                      <Link
                        to="/profile/preview "
                        className="px-3 py-2 d-flex align-items-center w-100"
                      >
                        <span className="me-3">
                          <SVG src={preview} />
                        </span>
                        <span>Profile Preview</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="user-p d-flex p-0"
                      onClick={onToggleClick}
                    >
                      <Link
                        to="/profile/user-profile"
                        className="px-3 py-2 d-flex align-items-center w-100"
                      >
                        <span className="me-3">
                          <SVG src={userIcon} />
                        </span>
                        <span> User Profile</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="lo lg-out-btn d-none d-lg-block px-3 py-2"
                      href="/logout"
                    >
                      <span className="me-3">
                        <SVG src={logout} />
                      </span>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link
                  to="/profile/preview"
                  className=" d-block d-lg-none mobile-link"
                >
                  Profile Preview
                </Link>
                <Link
                  to="/profile/user-profile"
                  className=" d-block d-lg-none mobile-link"
                >
                  User Profile
                </Link>
                <Link to="/logout">
                  <Buttons
                    btnText={"Logout"}
                    buttonName={"button"}
                    className="d-block d-lg-none"
                  ></Buttons>
                </Link>
              </div>
            ) : (
              <div className="btn-groups ">
                <Link to="/auth/login" className="btn btn-primary me-lg-3">
                    <FormattedMessage id="home.login" defaultMessage={"Login"}/>
                </Link>
                <Link to="/auth/login" className="btn btn-primary btn-reverse">
                    <FormattedMessage id="home.signup" defaultMessage={"Sign up"}/>
                </Link>
              </div>
            )}
          </Nav>          
        </Navbar.Collapse>
      </Container>
          )}
    </Navbar>
  );
}

export default Navigation;
