import React from "react";
import loader from "../../../templates/images/misc/loading.gif";

const Loading = () => {
  return (
    <>
      <div>
        <img src={loader} alt="loading..." /> 
      </div>
    </>
  );
};

export default Loading;
