import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Container, Row, Col } from "react-bootstrap-v5";
import Form from "react-bootstrap/Form";
import clsx from "clsx";
import Buttons from "../components/Buttons";
import InputField from "../components/edit-template/InputField";

import user01 from "../templates/images/user01.png";
import "../scss/user-profile.scss";
import "../scss/template/social-media.scss";
import "../scss/template/edit-template.scss";
import SocialMedia from "../components/edit-template/SocialMedia";

import whatsapp02 from "../templates/images/icons/whatsapp02.svg";
import instagram02 from "../templates/images/icons/instagram02.svg";
import youtube02 from "../templates/images/icons/youtube02.svg";
import twitter02 from "../templates/images/icons/twitter02.svg";
import facebook02 from "../templates/images/icons/facebook02.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../setup";
import { Avatar } from "@mui/material";
import { useFormik } from "formik";
import { TemplateValidate } from "../app/pages/templates/helper/TemplateValidate";
import { initTemplateUserData } from "../app/pages/templates/helper/InitTemplateData";
import { toast } from "react-toastify";
import { addTemplateUserData } from "../app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "../app/modules/template/redux/TemplateUserDataRedux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadFileNew } from "../components/edit-template/UploadFile";
import CropProfileImage from "../app/pages/templates/components/Templates/common/Crop/CropProfileImage";

const BasicProfileValidationSchema = TemplateValidate;

function  UserProfile() {
  const navigate = useNavigate();
  const dispatch =  useDispatch();
  const templateUserData = useSelector(
    (state: RootState) => state && state.templateUserData && state.templateUserData.templateUserData && state.templateUserData.templateUserData
  );
  const isAuthorized = useSelector(
    (state: RootState) => state && state.auth && state.auth.isAuthorized && state.auth.isAuthorized
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showCroper, setShowCroper] = useState<boolean>(false);
  const [cropperImageInput, setCropperImageInput] = useState<string>("");
  const [whatsAppLinkEdited, setWhatsAppLinkEdited] = useState<boolean>(false);

  useEffect(() => {
    if(formik?.values?.whatsAppLink === null){
      formik.setValues({
        ...formik.values,
        whatsAppLink: formik.values.mobileNumber
      })
    }
  },[])
 
  const formik = useFormik({
    initialValues: isAuthorized
      ? templateUserData
      : templateUserData
      ? templateUserData
      : initTemplateUserData,
    validationSchema: BasicProfileValidationSchema,
    onSubmit: (values: unknown, { setSubmitting }: unknown) => {
      setLoading(true);
      setTimeout(() => {
        if (isAuthorized) {     
          addTemplateUserData({
            ...values,
            id: templateUserData.id,
            status: "Published",
            isWhatsapp: formik.values.whatsAppLink ? true : false,
            isYouTube: formik.values.youTubeLink ? true : false,
            isInstagram: formik.values.instagramLink ? true : false,
            isTwitter: formik.values.twitterLink ? true : false,
            isFacebook: formik.values.facebookLink ? true : false,
            isWebsite: formik.values.websiteLink ? true : false,
            templateColor: templateUserData?.templateColor ? templateUserData?.templateColor : "#234170",
            templateSecondaryColor : templateUserData?.templateSecondaryColor ? templateUserData?.templateSecondaryColor : "#f4f7fc",
          })
            .then(() => {
              setLoading(false);
              dispatch(
                templateUserDataRedux.actions.setTemplateUserData({
                  ...templateUserData,
                  status: "Published",
                })
              );
              dispatch(templateUserDataRedux.actions.unsetFromEdit());
              toast.success("Success...! Saved, and Published");
              setTimeout(() => {
                navigate(`/profile/preview`);
              }, 300);
            })
            .catch((e) => {
              console.error(e)
              setLoading(false);
              setSubmitting(false);
              dispatch(templateUserDataRedux.actions.unsetFromEdit());
            });
        } else {
          dispatch(templateUserDataRedux.actions.setFromEdit(true));
          setTimeout(() => {
            navigate(`/auth/login`, {
              state: {
                mobileNumber: values.mobileNumber,
              },
            });
          }, 300);
        }
      }, 100);
    },
  });

  const handleFileUpload = (
    e: unknown,
    upFor: string
  ) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (upFor === "compunknownLogo") {
        setShowCroper(true);
      } else {
        setCropperImageInput(reader.result as string);
        setShowCroper(true);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCloseCropper = (act: boolean) => {
    setShowCroper(act);
  };

  const onImageChange = (
    img: string | File,
    imgFor: string,
    croppedImgArea: unknown,
    file: unknown,
  ) => {
    const reader = new FileReader();
    if (imgFor === "profile_image") {
      reader.readAsDataURL(file);   
      formik.setValues({
        ...formik?.values,
        profile_image: URL.createObjectURL(file),
        croppedAreaProfile: JSON.stringify(croppedImgArea),
      });
    }
  };

  const handleSwitch = (e: unknown) => {
    const value = e.target.value;
    const name = e.target.name;

    if(value === "" && name === "whatsAppLink"){
     formik.setValues({
       ...formik.values,
       isWhatsapp: false,
       [name]: null
     });
    }
    if(value === "" && name === "instagramLink"){
     formik.setValues({
       ...formik.values,
       isInstagram: false,
       [name]: null
     });
    }
    if(value === "" && name === "youTubeLink"){
     formik.setValues({
       ...formik.values,
       isYouTube: false,
       [name]: null
     });
    }
    if(value === "" && name === "facebookLink"){
     formik.setValues({
       ...formik.values,
       isFacebook: false,
       [name]: null
     });
    }
    if(value === "" && name === "twitterLink"){
     formik.setValues({
       ...formik.values,
       isTwitter: false,
       [name]: null
     });
    }
    if(value === "" && name === "websiteLink"){
     formik.setValues({
       ...formik.values,
       isWebsite: false,
       [name]: null
     });
    }
 }

 const handleCancelButton = () => {
  navigate("/profile/preview");
 }  

  return (
    <>
      <main className="main">
      <Breadcrumbs title="" templates="Cards / Edit User Profile" />
        <div className="user-edit-profile">
          <div className="edit-profile-wrap">
            <Container>
              <form 
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit()
              }}>
                <CropProfileImage                                                                           
                  show={showCroper}
                  photoURL={cropperImageInput}
                  setShowCroper={setShowCroper}
                  cropperImageInput={cropperImageInput}
                  closeCropper={handleCloseCropper}
                  onCropped={onImageChange}
                  handleFileUpload={handleFileUpload}
                />
                <Row>
                  <div className="col-12 col-lg-6 edit-basic-info order-2 order-lg-1">
                    <div className="user-info">
                      <div className="d-flex justify-content-between align-items-center pb-3">
                        <div className="">Basic Info</div>
                      </div>
                      <Row>
                        <div className="  col-md-6">
                          <div className="floating-group">
                            <InputField
                              type="text"
                              className=""
                              placeholder=" "
                              name="firstName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onFocus={formik.handleBlur}
                              isTouched={
                                formik.touched.firstName && formik.errors.firstName
                                  ? true
                                  : false
                              }
                              errorMsg={
                                formik.errors.firstName
                                  ? " "
                                  : ""
                              }
                              id="firstName"
                              value={formik?.values?.firstName ? formik?.values?.firstName : ""}
                              isDisabled={false}
                              autoFocus={false}
                            />
                            <label className="float-label">First Name</label>
                            {formik.touched.firstName && formik.errors.firstName !== "" && (
                              <div className="fv-plugins-message-container danger-validate">
                                <div className="fv-help-block" style={{ paddingTop : "6px" }}>{formik.errors.firstName}</div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" col-md-6">
                          <div className="floating-group">
                            <InputField
                              type="text"
                              className=""
                              placeholder=" "
                              name="lastName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onFocus={formik.handleBlur}
                              isTouched={
                                formik.touched.lastName && formik.errors.lastName
                                  ? true
                                  : false
                              }
                              errorMsg={
                                formik.errors.lastName ? " " : ""
                              }
                              id="lastName"
                              value={formik?.values?.lastName}
                              isDisabled={false}
                              autoFocus={false}
                            />
                            <label className="float-label">Last Name</label>
                            {formik.touched.lastName && formik.errors.lastName !== "" && (
                              <div className="fv-plugins-message-container danger-validate">
                                <div className="fv-help-block" style={{ paddingTop : "6px" }}>{formik.errors.lastName}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Row>
                      <div className="col">
                        <div className="floating-group ">
                          <InputField
                            type="email"
                            className="mb-3"
                            placeholder=" "
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            isTouched={
                              formik.touched.email && formik.errors.email
                                ? true
                                : false
                            }
                            errorMsg={
                              formik.errors.email ? formik.errors.email : ""
                            }
                            value={formik?.values?.email}
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Email</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="designation"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            isTouched={
                              formik.touched.designation &&
                              formik.errors.designation
                                ? true
                                : false
                            }
                            errorMsg={
                              formik.errors.designation
                                ? formik.errors.designation
                                : ""
                            }
                            id="designation"
                            value={formik && formik.values && formik.values.designation && formik.values.designation}
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Degination</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="floating-group ">
                          <InputField
                            type="tel"
                            className="mb-3"
                            placeholder=" "
                            name="mobileNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            isTouched={
                              formik.touched.mobileNumber &&
                              formik.errors.mobileNumber
                                ? true
                                : false
                            }
                            errorMsg={
                              formik.errors.mobileNumber
                                ? formik.errors.mobileNumber
                                : ""
                            }
                            id="mobileNumber"
                            value={formik && formik.values && formik.values.mobileNumber && formik.values.mobileNumber}
                            isDisabled={formik?.values?.mobileNumber ? true : false}
                            autoFocus={false}
                          />
                          <label className="float-label">Contact Number</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="websiteLink"
                            onChange={(e) => {
                              formik.handleChange(e)
                              handleSwitch(e)
                            }}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            isTouched={
                              formik.touched.websiteLink &&
                              formik.errors.websiteLink
                                ? true
                                : false
                            }
                            errorMsg={
                              formik.errors.websiteLink
                                ? formik.errors.websiteLink
                                : ""
                            }
                            id="websiteLink"
                            value={formik?.values?.websiteLink}
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Website</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            value={formik?.values?.address}
                            isTouched={
                              formik.touched.address && formik.errors.address
                                ? true
                                : false
                            }
                            errorMsg={
                              formik.errors.address ? formik.errors.address : ""
                            }
                            autoFocus={false}
                          />
                          <label className="float-label">Address</label>
                        </div>
                      </div>
                      <div className="col">
                       <div className="floating-group w-100">
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder=" "
                            className={clsx(
                              `h-auto form-control`,
                              {
                                "is-invalid": (formik.touched.description && formik.errors.description) && "errorMsg",
                              },
                              {
                                "is-valid ": (formik.touched.description && !formik.errors.description) && "!errorMsg",
                              }
                            )}
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            value={formik?.values?.description}
                            autoFocus={false}
                          />
                          <label className="float-label">About me</label>
                          {formik.touched.description && formik.errors.description !== "" ? (
                              <div className="fv-plugins-message-container danger-validate">
                                <div className="fv-help-block" style={{ paddingTop : "15px" }}>{formik.errors.description}</div>
                              </div>
                            ): ""}
                           </div> 
                      </div>
                      <Col className="col-12 col-lg-6 d-block d-lg-none">
                        <Buttons
                          btnText={"Cancel"}
                          buttonName={"buttonCancel"}
                          type="button"
                          className="w-100 btn-reverse mb-3"
                        />
                      </Col>
                      <Col className="col-12 col-lg-6 d-block d-lg-none">
                        <Buttons
                          btnText={"Save"}
                          buttonName={"button"}
                          type="submit"
                          className="w-100 mb-3"
                        />
                      </Col>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6  d-flex flex-column align-items-center justify-content-center order-1 order-lg-2">
                    <div className="w-100 upload-profile-sec">
                      <div className="mb-4 text-center">
                        <div className="img-wrap mx-auto">
                          <div className="position-relative">
                            <Avatar src={(templateUserData?.profile_image?.filename_disk || formik?.values?.profile_image?.filename_disk) ? formik?.values?.profile_image?.filename_disk : user01}   
                              alt="userimg"
                              style={{                               
                                width: "150px",
                                height: "150px",
                                border: "2px solid #6a62d4",
                                borderRadius: "50%",
                                boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
                                position: "relative",     
                                marginRight: "auto",
                                marginLeft: "auto",                     
                              }}
                              className="user-img-class"
                            />
                            <div className="edit-btn" style={{ cursor: "pointer" }}>
                              <UploadFileNew
                                name="profile_image"
                                className="file-upload"
                                id="uploaderProfile"
                                onChange={(e: unknown) =>
                                  handleFileUpload(e, "profile_image")
                                }
                                title="Upload Your Photo"
                                isTouched={
                                  formik.touched.profile_image &&
                                  formik.errors.profile_image
                                    ? true
                                    : false
                                }
                                errorMsg={
                                  formik.errors.profile_image
                                    ? "Profile Image Required"
                                    : ""
                                }
                                userProfile={true}
                              />
                            </div>
                          </div>
                        </div>
                        {formik.touched.profile_image && formik.errors.profile_image !== "" ? (
                              <div className="fv-plugins-message-container danger-validate">
                                <div className="fv-help-block" style={{ paddingTop : "15px" }}>{formik.errors.profile_image}</div>
                              </div>
                            ) : ""}
                      </div>
                      <div className="label-18 mb-3">Social Media Links</div>
                      <Row>
                        <div className="col-md-6">
                          <SocialMedia
                            svg={whatsapp02}
                            placeHolder="* WhatsApp Number"
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            onLinkInput={(e) =>  {
                              formik.handleChange(e)
                              setWhatsAppLinkEdited(true);
                            }}
                            switchName="isWhatsapp"
                            switchHtmlFor="isWhatsapp"
                            switchId="isWhatsapp"
                            name="whatsAppLink"
                            id="whatsAppLink"
                            isTouched={formik.touched.whatsAppLink ? true : false }
                            errorMsg={
                              formik.errors.whatsAppLink
                                ? formik.errors.whatsAppLink
                                : ""
                            }
                            value={(formik && formik.values && formik.values.whatsAppLink && formik.values.whatsAppLink)
                              ? (formik && formik.values && formik.values.whatsAppLink && formik.values.whatsAppLink) 
                              : (whatsAppLinkEdited && whatsAppLinkEdited)
                              ? (formik && formik.values && formik.values.whatsAppLink && formik.values.whatsAppLink)
                              : (formik && formik.values && formik.values.mobileNumber && formik.values.mobileNumber)}   
                          />
                        </div>
                        <div className="col-md-6">
                          <SocialMedia
                            svg={youtube02}
                            id="youTubeLink"
                            name="youTubeLink"
                            placeHolder={
                              formik.values?.isYouTube
                                ? " YouTube url"
                                : " YouTube url"
                            }
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            onLinkInput={(e) =>  {
                              formik.handleChange(e)
                              handleSwitch(e)
                            }}  
                            isTouched={formik.touched.youTubeLink ? true : false }
                            errorMsg={
                              formik.errors.youTubeLink
                                ? formik.errors.youTubeLink
                                : ""
                            }                          
                            switchName="isYouTube"
                            switchHtmlFor="isYouTube"
                            switchId="isYouTube"
                            value={formik && formik.values && formik.values.youTubeLink && formik.values.youTubeLink}                           
                          />
                        </div>
                        <div className="col-md-6">
                          <SocialMedia
                            svg={instagram02}
                            id="instagramLink"
                            name="instagramLink"
                            placeHolder={
                              formik.values?.isInstagram
                                ? " Instagram url"
                                : " Instagram url"
                            }
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            onLinkInput={(e) => {
                              formik.handleChange(e)
                              handleSwitch(e)
                            }}
                            isTouched={formik.touched.instagramLink ? true : false }
                            errorMsg={
                              formik.errors.instagramLink
                                ? formik.errors.instagramLink
                                : ""
                            }
                            switchHtmlFor="isInstagram"
                            switchId="isInstagram"
                            switchName="isInstagram"
                            isSwitch={true}
                            value={formik && formik.values && formik?.values?.instagramLink}                            
                          />
                        </div>

                        <div className="col-md-6">
                          <SocialMedia
                            svg={facebook02}
                            id="facebookLink"
                            name="facebookLink"
                            placeHolder={
                              formik.values?.isFacebook
                                ? " Facebook url"
                                : " Facebook url"
                            }
                            value={formik && formik.values && formik?.values?.facebookLink}
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            onLinkInput={(e) =>  {
                              formik.handleChange(e)
                              handleSwitch(e)
                            }}
                            isTouched={formik.touched.facebookLink ? true : false }
                            errorMsg={
                              formik.errors.facebookLink
                                ? formik.errors.facebookLink
                                : ""
                            }
                            switchName="isFacebook"
                            switchHtmlFor="isFacebook"
                            switchId="isFacebook"
                            isSwitch={true}
                          />
                        </div>

                        <div className="col-md-12">
                          <SocialMedia
                            svg={twitter02}
                            id="twitterLink"
                            name="twitterLink"
                            placeHolder={
                              formik.values?.isTwitter
                                ? " Twitter url"
                                : " Twitter url"
                            }
                            onBlur={formik.handleBlur}
                            onFocus={formik.handleBlur}
                            onLinkInput={(e) =>  {
                              formik.handleChange(e)
                              handleSwitch(e)
                            }}
                            isTouched={formik.touched.twitterLink ? true : false }
                            errorMsg={
                              formik.errors.twitterLink
                                ? formik.errors.twitterLink
                                : ""
                            }
                            switchName="isTwitter"
                            switchHtmlFor="isTwitter"
                            switchId="isTwitter"
                            isSwitch={true}
                            value={formik && formik.values && formik?.values?.twitterLink}                            
                          />
                        </div>

                        <Col className="col-12 col-lg-6 d-none d-lg-block">
                          <Buttons
                            btnText={"Cancel"}
                            buttonName={"button"}
                            type="button"
                            className="w-100 btn-reverse mb-3"
                            onClick={handleCancelButton}
                          />
                        </Col>
                        <Col className="col-12 col-lg-6 d-none d-lg-block">
                          <Buttons
                            btnText={loading ? "Please wait..." : "Save"}
                            buttonName={"button"}
                            type="submit"
                            className="w-100 mb-3"
                            disabled={loading ? true : false}
                          /> 
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
              </form>
            </Container>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserProfile;
