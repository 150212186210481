import React from "react";
import BreadcrumbSidebar from "../components/BreadcrumbSidebar";
import Sidebarnav from "../components/Sidebarnav";
import  "../scss/dashboard.scss"
import Accordion from "react-bootstrap/Accordion";
import SocialMedia from "../components/edit-template/SocialMedia";
import whatsapp02 from "../templates/images/icons/whatsapp02.svg";
import { UploadFileNew } from "../components/edit-template/UploadFile"; 
import InputField from "../components/edit-template/InputField";
import Template010 from "../templates/Template010";

const Dashboard = () => { 
  return (
    <>
      <header className="side-nav-wrapper">
        <Sidebarnav />
      </header>
      <main className="main">
        <BreadcrumbSidebar title="Dashboard" templates="Dashboard View" />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <Accordion defaultActiveKey={["0"]} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>General information</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="firstName"
                            id="firstName"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">First Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-group">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="lastName"
                            id="lastName"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Last Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-group">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="designation"
                            id="designation"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Designation</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-group ">
                          <InputField
                            type="tel"
                            className="mb-3"
                            placeholder=" "
                            name="mobileNumber"
                            id="mobileNumber"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Contact No.</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-group">
                          <InputField
                            type="email"
                            className="mb-3"
                            placeholder=" "
                            name="email"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Email</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="websiteLink"
                            id="websiteLink"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Website</label>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>Upload images</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-lg-6  upload-side d-flex flex-column">
                        <h6 className="text-center  mb-3">
                          Upload your image
                        </h6>
                        <div className="upload-box">
                          <UploadFileNew
                            name="profileImage"
                            className="file-upload"
                            id="uploader"
                            onChange={function (): void {
                              throw new Error("Function not implemented.");
                            } }
                            isTouched={false}
                            errorMsg={""}
                            title={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="text-center mb-3">
                          Upload Company Logo
                        </h6>
                        <div className="upload-box">
                          <UploadFileNew
                            name="profileImage"
                            className="file-upload"
                            id="uploader"
                            onChange={function (): void {
                              throw new Error("Function not implemented.");
                            }}
                            isTouched={false}
                            errorMsg={""}
                            title={""}
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Business Information</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="Company Name"
                            id="companyname"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Company Name</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-group ">
                          <InputField
                            type="text"
                            className="mb-3"
                            placeholder=" "
                            name="Address"
                            id="address"
                            isDisabled={false}
                            autoFocus={false}
                          />
                          <label className="float-label">Address</label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="floating-group ">
                          <textarea
                            className="w-100 form-control"
                            placeholder=" "
                            id="w3review"
                            name="w3review"
                            rows={5}
                            cols={30}
                          ></textarea>
                          <label className="float-label">About</label>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Select the services</Accordion.Header>
                  <Accordion.Body>
                    {/* <img src="../../src/templates/images/services-items.png" alt="service-img" /> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Social Media </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-lg-6">
                        <SocialMedia
                          svg={whatsapp02}
                          placeHolder={"* WhatsApp Number"}
                          id={"whatsAppLink"}
                          value={"whatsAppLink"}
                        />
                      </div>
                      <div className="col-lg-6">
                        <SocialMedia
                          svg={whatsapp02}
                          placeHolder={"* WhatsApp Number"}
                          id={"whatsAppLink"}
                          value={"whatsAppLink"}
                        />
                      </div>
                      <div className="col-lg-6">
                        <SocialMedia
                          svg={whatsapp02}
                          placeHolder={"* WhatsApp Number"}
                          id={"whatsAppLink"}
                          value={"whatsAppLink"}
                        />
                      </div>
                      <div className="col-lg-6">
                        <SocialMedia
                          svg={whatsapp02}
                          placeHolder={"* WhatsApp Number"}
                          id={"whatsAppLink"}
                          value={"whatsAppLink"}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div className="text-center mb-4">
                <button
                  type="submit"
                  name="button"
                  className="mt-4 btn btn-primary min-w-300"
                >
                  Save{" "}
                </button>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <Template010 companyLogoPreview={''} profilePreview={''} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
