import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SVG from "react-inlinesvg";
import pocketlogo from "../../templates/images/pocketsmall.svg";
import { FormattedMessage } from "react-intl";
import { TemplateUserDataModel } from "../../app/modules/template/models/TemplateUserDataModel";
import { tracker } from "../../app/helper/helper";
import { SHAREBLE_URL_CREATE_YOURS_BUTTON_CLICK, SHAREBLE_URL_POCKETSITE_ICON_CLICK } from "../../app/helper/EventsConst";
import { useDispatch } from "react-redux";
import { actions } from "../../app/modules/auth";

type Props = {
  sloading: boolean | undefined;
  userTemplateByID: boolean | undefined;
  fromEditTemplate: boolean | undefined;
  buttonStyles: React.CSSProperties | undefined;
  handleMouseEnter: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleMouseLeave: React.MouseEventHandler<HTMLButtonElement> | undefined;
  mobileUserData: TemplateUserDataModel | undefined;
  editMode: boolean | undefined;
  language?: string;
};

function TemplateFooter({
  sloading,
  userTemplateByID,
  fromEditTemplate,
  buttonStyles,
  handleMouseEnter,
  handleMouseLeave,
  mobileUserData,
  language
}: Props) {

  const dispatch = useDispatch()
  
  const HandleClick = () => {
    tracker({ eventName: SHAREBLE_URL_CREATE_YOURS_BUTTON_CLICK })
    window.open("auth/login", "_blank");
  };
  const HandleGALogoClick = () => {
    tracker({ eventName: SHAREBLE_URL_POCKETSITE_ICON_CLICK })
  }
  
  useEffect(() =>{
    if(language){
      dispatch(actions.setLanguage(language))    
    }
  },[])

  useEffect(() => {
    if (language) {
      dispatch(actions.setLanguage(language))
    }
  }, [])

  return (
    <>
      {mobileUserData ? (
        <div className="d-flex align-items-center powered-block ps-2 justify-content-between">
          {(sloading || userTemplateByID === false) &&
            fromEditTemplate === false ? (
            <Skeleton width={180} height={22} />
          ) : (
            <div>
              <FormattedMessage
                id="preview.Powered.by"
                defaultMessage={"Powered by"}
              />
              <a
                href="https://pocketsite.me"
                target="_blank"
                title="Pocketsite"
                rel="noreferrer"

              >
                <span className="ps-2 pe-1">
                  <SVG
                    src={pocketlogo}
                    className="logosmall"
                    width={18}
                    height={18}
                    onClick={HandleGALogoClick}
                  />
                </span>
                Pocketsite.me
              </a>
            </div>
          )}
          {(sloading || userTemplateByID === false) &&
          fromEditTemplate === false ? (
            <Skeleton width={80} height={24} style={{ borderRadius: "20px" }} />
          ) : (
            <button
              style={buttonStyles}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={HandleClick}
            >
              <FormattedMessage
                id="home.create.yours"
                defaultMessage={"Create Yours"}
              />
            </button>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center powered-block ps-2 justify-content-between">
          {
            ((sloading || userTemplateByID === false) &&
              fromEditTemplate === false ? (
              <Skeleton width={180} height={22} />
            ) : (
              <div>
               <FormattedMessage
                id="preview.Powered.by"
                defaultMessage={"Powered by"}
              />
                <a
                  href="https://pocketsite.me"
                  target="_blank"
                  title="Pocketsite"
                  rel="noreferrer"
                  onClick={HandleGALogoClick}
                >
                  <span className="ps-2 pe-1">
                    <SVG
                      src={pocketlogo}
                      className="logosmall"
                      width={18}
                      height={18}
                    />
                  </span>
                  Pocketsite.me
                </a>
              </div>
            ))}
          {
            ((sloading || userTemplateByID === false) &&
              fromEditTemplate === false ? (
              <Skeleton
                width={80}
                height={24}
                style={{ borderRadius: "20px" }}
              />
            ) : (
              <button
                style={buttonStyles}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={HandleClick}
              >
                <FormattedMessage
                  id="home.create.yours"
                  defaultMessage={"Create Yours"}
                />
              </button>
            ))}
        </div>
      )}
    </>
  );
}

export default TemplateFooter;
