import React, { useEffect, lazy, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import close from "../src/templates/images/icons/close.svg";
import { getTemplateUserDataByMobileNumber } from "./app/modules/template/redux/TemplateUserDataCRUD";
import * as templateUserDataRedux from "./app/modules/template/redux/TemplateUserDataRedux";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { downloadVCFFile } from "./downloadVCF";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { TemplateUserDataModel } from "./app/modules/template/models/TemplateUserDataModel";
import { FormattedMessage } from "react-intl";
import "./index.scss";
import "./scss/template/edit-template.scss";
import "../src/templates/scss/temp02.scss";
import "../src/templates/scss/temp03.scss";
import "../src/templates/scss/temp04.scss";
import "../src/templates/scss/temp05.scss";
import "../src/templates/scss/temp07.scss";
import { tracker } from "./app/helper/helper";
import { SHAREABLE_URL_IMPRESSION } from "./app/helper/EventsConst";
import { RootState } from "./setup";

export type OnHideFunction = () => void;

type Props = {
  mobileNumber: string;
};

const importView = (templateId: number | undefined) => {
  switch (templateId) {
    case 6:
      return lazy(() =>
        import(`./templates/Template06`).catch(
          () => import(`./app/pages/NotFound/NotFoundComponent`)
        )
      );
    case 7:
      return lazy(() =>
        import(`./templates/Template07`).catch(
          () => import(`./app/pages/NotFound/NotFoundComponent`)
        )
      );
    case 8:
      return lazy(() =>
        import(`./templates/Template08`).catch(
          () => import(`./app/pages/NotFound/NotFoundComponent`)
        )
      );
    case 9:
      return lazy(() =>
        import(`./templates/Template09`).catch(
          () => import(`./app/pages/NotFound/NotFoundComponent`)
        )
      );
    case 10:
      return lazy(() =>
        import(`./templates/Template010`).catch(
          () => import(`./app/pages/NotFound/NotFoundComponent`)
        )
      );
    default:
      return null;
  }
};
export const PocketWebsite = ({ mobileNumber }: Props) => {
  const dispatch = useDispatch();
  const [templateData, setTemplateData] = useState<TemplateUserDataModel>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const vcfDownloaded = localStorage.getItem("vcf-download");
  const [views, setViews] = useState<React.ReactNode>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let last10Digit: string;
  const { templateList } = useSelector((state: RootState) => state.template);
  if (mobileNumber.length >= 10) {
    last10Digit = mobileNumber.substring(mobileNumber.length - 10);
  } else {
    last10Digit = mobileNumber;
  }

  useEffect(() => {
    if (templateData) {
      tracker({
        eventName: SHAREABLE_URL_IMPRESSION,
        dataInfo: {
          mobileNumber: templateData?.mobileNumber,
          userName: templateData?.firstName + " " + templateData?.lastName,
        },
      });
    }
  }, [templateData]);

  useEffect(() => {
    getUSerTemplateData();
  }, [mobileNumber, last10Digit]);

  const handleAddToContact = () => {
    setShowModal(false);
    localStorage.setItem("vcf-download", "false");
  };

  useEffect(() => {
    if (showModal) {
      const socialModal = document.querySelector("#ps_social_sharing_modal");
      if (socialModal) {
        socialModal.classList.remove("show");
      }
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      const reviewModal = document.querySelector("#ps_review_modal");
      if (reviewModal) {
        reviewModal.classList.remove("show");
      }
    }
  }, [showModal]);

  async function loadViews(tempId: number) {
    const componentPromises = async (tempId: number) => {
      const selectedTemplate = templateList?.find(
        (template) => template.id === tempId
      );
      const sendKey = selectedTemplate?.templateKey;
      const View = importView(sendKey);

      const naid: string = nanoid(10);
      // if (tempId !== -1) {
      //   if (vcfDownloaded !== "true") {
      //     setTimeout(() => {
      //       setShowModal(true);
      //     }, 15000);
      //   }
      // }
      return (
        <View
          key={naid}
          editMode={false}
          showAddToContactModal={setShowModal}
          templateId={tempId}
          visitProfilePreview={true}
          skeletonLoading={isLoading}
          setSkeletonLoading={setIsLoading}
          reacTour={false}
        />
      );
    };
    componentPromises(tempId).then(setViews);
  }

  const getUSerTemplateData = () => {
    getTemplateUserDataByMobileNumber(last10Digit)
      .then((res) => {
        // setUserData({ ...res.data.data[0] });
        // setMetaData([
        //   {
        //     property: "og:title",
        //     content: `${res.data.data[0]?.firstName} ${res.data.data[0]?.lastName}`,
        //   },
        //   {
        //     property: "og:description",
        //     content: `${res.data.data[0]?.description}`,
        //   },
        //   {
        //     property: "og:url",
        //     content: `https://pocketsite.me/${res.data.data[0].mobileNumber}`,
        //   },
        //   {
        //     property: "og:type",
        //     content: `website`,
        //   },
        //   {
        //     property: "og:image",
        //     content: `/media/logos/main-logo.png`,
        //   },
        // ]);

        if (
          res.data.data[0].status === "Published" ||
          res.data.data[0].status === "published"
        ) {
          dispatch(
            templateUserDataRedux.actions.setTemplateUserData({
              ...res.data.data[0],
            })
          );
          dispatch(templateUserDataRedux.actions.setUserTemplateByID(true));
          const tempId = res.data.data[0].selectedTemplateID;
          setTemplateData(res.data.data[0]);
          loadViews(tempId);
        } else {
          loadViews(-1);
        }
      })
      .catch(() => {
        toast.error(
          <FormattedMessage
            id="toast.message.card.notexist"
            defaultMessage={"The card you are looking for doesn't exist"}
          />
        );
        loadViews(-1);
      });
  };

  return (
    <>
      <SkeletonTheme
        baseColor={
          templateData?.templateColor ? templateData?.templateColor : ``
        }
        highlightColor={templateData?.templateSecondaryColor}
      >
        <div className="d-flex justify-content-center mt-3 mb-3 overflow-auto">
          <Suspense fallback={""}>{views}</Suspense>
          {showModal && (
            <Modal
              show={showModal}
              onHide={handleAddToContact}
              backdrop="static"
              keyboard={false}
            >
              <div className="pt-3">
                <h3 className="pt-2 d-flex align-item-center justify-content-center">
                  Add To Contacts
                </h3>
                <button
                  className="btn color-close"
                  type="button"
                  onClick={handleAddToContact}
                >
                  <SVG src={close} />
                </button>
                <hr></hr>
              </div>
              <Modal.Body>
                <div className="text-center">
                  Do you want to add{" "}
                  <b>
                    {templateData?.firstName + " " + templateData?.lastName}
                  </b>
                  {`'s`} details to your phone contacts?
                </div>
                <hr></hr>
                <div className="mt-2">
                  Click on <b>Yes</b> to download and then click on downloaded
                  file{" "}
                  <b>
                    {templateData?.firstName + "" + templateData?.lastName}.vcf
                  </b>{" "}
                  to import it to your contacts
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="button-group d-flex w-100">
                  <button
                    type="button"
                    style={{
                      backgroundColor: `${
                        templateData?.templateColor
                          ? templateData?.templateColor
                          : "#00284d"
                      }`,
                      color: "white",
                      height: "45px",
                      borderStyle: "solid",
                      borderColor: `${
                        templateData?.templateSecondaryColor
                          ? templateData?.templateSecondaryColor
                          : "#00284d"
                      }`,
                    }}
                    className="w-50 mt-lg-4 mt-0 ms-lg-0 ms-2 me-2"
                    onClick={() => {
                      downloadVCFFile(templateData);
                      setShowModal(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    style={{
                      backgroundColor: `${
                        templateData?.templateColor
                          ? templateData?.templateColor
                          : "#00284d"
                      }`,
                      color: "white",
                      height: "45px",
                      borderStyle: "solid",
                      borderColor: `${
                        templateData?.templateSecondaryColor
                          ? templateData?.templateSecondaryColor
                          : "#00284d"
                      }`,
                    }}
                    className="w-50 mt-lg-4 mt-0 ms-lg-0 ms-2 me-2"
                    onClick={handleAddToContact}
                  >
                    No
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </SkeletonTheme>
    </>
  );
};
